import { trimOperationalRisk } from "./trimOperationalRisk"

/**
 * This function calculates the subsidy effect based on the passed in loan.
 * @param {object} loan - The loan.
 * @param {SubsidyEffectParams} loan.subsidyEffectParams - The subsidy effect params.
 * @param {number} loan.loanAmount - The loan amount.
 *
 *
 * @returns {{subsidyEffect: number, netPresentValue: number, deliveryId: string}|null} - The calculated `subsidyEffect`.
 *
 * @example
 * const loan = {
 *  loanAmount: 1000000,
 *  subsidyEffectParams: {
 *    terminerPerAar: 4,
 *    terminGebyr: 100,
 *    antallAar: 3,
 *    antallAvdragsFrieAar: 3,
 *    antallRenteFrieAar: 2,
 *    nominellRente: 0.08199999999999999,
 *    referanseRente: 0.0473,
 *    sikkerhetsRisiko: 7,
 *    driftsRisiko: "D",
 *    utbetalingsKurs: 0.995,
 *    laaneType: "SERIELAAN",
 *    frekvens: "KVARTALVIS",
 *    risikopaaslag: 0,
 *    deliveryId: "C0A5F1B0-F508-4972-B77B-F51C63CFCD6A",
 *    hasUpsideFee: false,
 *  }
 * }
 *
 * const result = calculateSubsidyEffect(loan)
 */

function calculateSubsidyEffect(loan) {
  try {
    const { loanAmount, subsidyEffectParams } = loan
    const {
      terminerPerAar,
      terminGebyr,
      antallAar,
      utbetalingsKurs,
      referanseRente,
      antallAvdragsFrieAar,
      antallRenteFrieAar = 0,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      laaneType,
      frekvens,
      deliveryId,
    } = subsidyEffectParams

    const { tableDiff } = createSubsidyEffectTable(loanAmount, {
      terminerPerAar,
      terminGebyr,
      antallAar,
      antallAvdragsFrieAar,
      antallRenteFrieAar,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      referanseRente,
      risikopaaslag: 0, // 0.01 - 0.1
      utbetalingsKurs,
      laaneType,
      frekvens,
      deliveryId,
    })
    /**
     * @type {number[]} - The cashflows.
     */
    const cashFlows =
      tableDiff?.map((loanTerm) => loanTerm.differanseKontantStrøm) ?? []

    const referanseRentePlusOnePercent = (referanseRente * 100 + 1) / 100
    const terminRenteSubsidieEffekt =
      Math.pow(1 + referanseRentePlusOnePercent, 1 / terminerPerAar) - 1

    const netPresentValue = calculateNPV(terminRenteSubsidieEffekt, cashFlows)

    const differanseKontantStrømUtbetaling =
      -loanAmount + loanAmount * utbetalingsKurs

    const subsidyEffectAmount =
      netPresentValue + differanseKontantStrømUtbetaling

    return {
      netPresentValue: netPresentValue,
      subsidyEffect: subsidyEffectAmount,
      deliveryId,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * This function calculates the subsidy effect for multiple loans.
 * @param {object[]} loans - The loans.
 * @param {SubsidyEffectParams} loans.subsidyEffectParams - The subsidy effect params.
 * @param {number} loans.loanAmount - The loan amount.
 * @returns {({subsidyEffect: number, netPresentValue: number, deliveryId: string} | null)[]} - The calculated subsidy effects.
 */
export default function calculateMultipleSubsidyEffects(loans) {
  const result = loans?.map((loan) => calculateSubsidyEffect(loan)) || []
  return result
}

/**
 * This function creates a table with the subsidy effect for each term.
 * @param {number} loanAmount - The loan amount.
 * @param {SubsidyEffectParams} subsidieEffectParams - The subsidy effect params.
 *
 * @returns {{tableLoan: TableLoan[], tableReferenceLoan: TableReferenceLoan[], tableDiff: TableDiff[], terminRenteReferanseLaan: number}} - The calculated table.
 */
function createSubsidyEffectTable(loanAmount, subsidieEffectParams) {
  const {
    terminerPerAar,
    terminGebyr,
    antallAar,
    antallAvdragsFrieAar,
    antallRenteFrieAar,
    nominellRente,
    referanseRente,
    risikopaaslag,
    utbetalingsKurs,
    laaneType,
    frekvens,
    sikkerhetsRisiko,
    driftsRisiko,
  } = subsidieEffectParams

  const frekvensInMonths = {
    AARLIG: 12,
    HALVAARLIG: 6,
    KVARTALVIS: 3,
    MAANEDLIG: 1,
  }
  const antallTerminer = terminerPerAar * (antallAar + antallAvdragsFrieAar)
  const frekvensInNumber = frekvensInMonths[frekvens]
  const risikoPaaslagCalculated = calculateRisikoPaaslag(
    sikkerhetsRisiko,
    driftsRisiko,
    risikopaaslag
  )

  const referanseRenteMedRisikoTillegg =
    referanseRente + risikoPaaslagCalculated

  // Limit to 15 decimals, to avoid floating point errors compared to Excel sheet
  const terminRenteReferanseLaan = (
    Math.pow(1 + referanseRenteMedRisikoTillegg, 1 / terminerPerAar) - 1
  ).toFixed(16)

  let avdragBeregnet = 0
  if (laaneType === "SERIELAAN") {
    avdragBeregnet = calculateAvdragSerielaan(
      loanAmount,
      antallTerminer,
      antallAvdragsFrieAar,
      terminerPerAar
    )
  } else {
    avdragBeregnet = calculateAnnuity(
      loanAmount,
      nominellRente,
      terminerPerAar,
      antallAar
    )
  }

  const tableLoan = []
  let currentSaldo = loanAmount

  const firstUtbetaltSum = loanAmount * utbetalingsKurs
  const saldoUtbetaling = {
    termin: 0,
    saldo: currentSaldo,
    utbetalt: -firstUtbetaltSum,
    rente: 0,
    avdrag: 0,
    kontantstrøm: -firstUtbetaltSum,
    terminBeløp: 0,
    gebyr: 0,
    terminRente: 0,
  }
  tableLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const isRenteFriPeriode = i < terminerPerAar * antallRenteFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    let terminGebyrAmount = terminGebyr
    let renteSumLoan = (currentSaldo * nominellRente) / terminerPerAar
    if (!isAvdragsfriPeriode) {
      currentSaldo = currentSaldo - avdragBeregnet
    }
    if (isRenteFriPeriode) {
      renteSumLoan = 0
      terminGebyrAmount = 0
    }
    tableLoan.push({
      termin: i + 1,
      rente: renteSumLoan,
      avdrag: avdragTermin,
      saldo: currentSaldo,
      kontantstrøm: avdragTermin + renteSumLoan + terminGebyrAmount,
      terminBeløp: avdragTermin + renteSumLoan + terminGebyrAmount,
      gebyr: terminGebyrAmount,
      utbetalt: 0,
      terminRente: Number(terminRenteReferanseLaan),
    })
  }

  let currentSaldoReferanseLaan = loanAmount
  const tableReferenceLoan = []
  saldoUtbetaling.utbetalt = -loanAmount
  saldoUtbetaling.kontantstrøm = -loanAmount
  tableReferenceLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    const renterReferanseLoan =
      Number(terminRenteReferanseLaan) * currentSaldoReferanseLaan

    if (isAvdragsfriPeriode) {
      // Hvis det er avdragsfri periode, så skal det ikke betales avdrag
    } else {
      currentSaldoReferanseLaan = currentSaldoReferanseLaan - avdragBeregnet
    }

    tableReferenceLoan.push({
      saldo: currentSaldoReferanseLaan,
      termin: i + 1,
      rente: renterReferanseLoan,
      avdrag: avdragTermin,
      kontantstrøm: avdragTermin + renterReferanseLoan,
      terminBeløp: 0,
      gebyr: 0,
      utbetalt: 0,
      terminRente: +terminRenteReferanseLaan,
    })
  }

  const tableDiff = []
  for (let i = 1; i <= antallTerminer; i++) {
    const differanseKontantStrøm =
      tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm
    tableDiff.push({
      saldo: tableLoan[i].saldo,
      termin: i,
      rente: tableLoan[i].rente,
      avdrag: tableLoan[i].avdrag,
      kontantstrøm:
        tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm,
      terminBeløp: tableLoan[i].terminBeløp,
      gebyr: 0,
      utbetalt: 0,
      terminRente: +terminRenteReferanseLaan,
      differanseKontantStrøm: differanseKontantStrøm,
      referanseKontantStrøm: tableReferenceLoan[i].kontantstrøm,
      låneKontantStrøm: tableLoan[i].kontantstrøm,
      låneRenter: tableLoan[i].rente,
      referanseRenter: tableReferenceLoan[i].rente,
    })
  }
  return {
    tableLoan: tableLoan,
    tableReferenceLoan: tableReferenceLoan,
    tableDiff,
    terminRenteReferanseLaan: +terminRenteReferanseLaan,
  }
}

/**
 * This function calculates the net present value of the cashflows.
 * then returns the calculated result.
 * @param {number} rate - The rate.
 * @param {number[]} cashFlows - The cashflows.
 * @returns {number} - The calculated NPV.
 *
 * @example
 * const result = calculateNPV(0.014027, [100, 200, 300])
 */

function calculateNPV(rate = -1, cashFlows) {
  let npv = 0
  // Start on term 1
  for (let i = 0; i < cashFlows.length; i++) {
    const cashFlow = cashFlows[i]
    npv += cashFlow / Math.pow(1 + rate, i + 1)
  }
  return npv
}

// Private function
/**
 * @param {number} loanAmount - The loan amount.
 * @param {number} rate - The rate.
 * @param {number} numberOfPaymentsPerYear - The number of payments per year.
 * @param {number} loanTermYears - The loan term in years.
 *
 * @returns {number} - The calculated annuity.
 */
function calculateAnnuity(
  loanAmount,
  rate,
  numberOfPaymentsPerYear,
  loanTermYears
) {
  const numberOfPayments = numberOfPaymentsPerYear * loanTermYears
  const monthlyRate = rate / (12 / numberOfPaymentsPerYear)
  const annuity =
    (loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
    (Math.pow(1 + monthlyRate, numberOfPayments) - 1)
  return annuity
}

// Private function
/**
 * @param {number} loanAmount - The loan amount.
 * @param {number} antallTerminer - The number of terms.
 * @param {number} antallAvdragsFrieAar - The number of years with no repayments.
 * @param {number} terminerPerAar - The number of terms per year.
 * @returns {number} - The calculated repayment.
 *
 * @example
 * const result = calculateAvdragSerielaan(1000000, 24, 3, 4)
 */
function calculateAvdragSerielaan(
  loanAmount,
  antallTerminer,
  antallAvdragsFrieAar,
  terminerPerAar
) {
  return loanAmount / (antallTerminer - terminerPerAar * antallAvdragsFrieAar)
}

/**
 * This function calculates the risk surcharge.
 * @param {number} sikkerhetsRisiko - The security risk.
 * @param {OperationalRisk} driftsRisiko - The operational risk.
 * @param {number} risikopaaslag - The risk surcharge.
 * @returns {number} - The calculated risk surcharge.
 *
 * @example
 * const result = calculateRisikoPaaslag(7, "D", 0)
 */
function calculateRisikoPaaslag(
  sikkerhetsRisiko = 1,
  driftsRisiko = "A", // "A" | "B" | "C" | "D"
  risikopaaslag = 0
) {
  const driftsRisikoArray = ["A", "B", "C", "D"]
  const tapsProsent = [
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [1.0, 2.2, 4.0, 6.5],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
  ]
  // Get index of driftsRisiko
  if (risikopaaslag > 0) {
    return risikopaaslag
  }
  const indexOfDriftsRisiko = driftsRisikoArray.indexOf(driftsRisiko)
  return tapsProsent[sikkerhetsRisiko - 1][indexOfDriftsRisiko] / 100
}

/**
 * @typedef {"AARLIG" | "HALVAARLIG" | "KVARTALVIS" | "TRE_GANGER_I_AARET" | "BIMAANEDLIG" | "MAANEDLIG"} PaymentFrequencyNorwegian - The payment frequency in Norwegian.
 */

/**
 * @param {PaymentFrequencyNorwegian} frequency
 * @returns {number} - The number of terms per year.
 *
 * @example
 * const result = convertFrequencyToNumber("AARLIG")
 */
export const convertFrequencyToNumber = (frequency) => {
  switch (frequency) {
    case "AARLIG":
      return 1
    case "HALVAARLIG":
      return 2
    case "KVARTALVIS":
      return 4
    case "TRE_GANGER_I_AARET":
      return 3
    case "BIMAANEDLIG":
      return 6
    case "MAANEDLIG":
      return 12
    default:
      return 1
  }
}

/**
 * @param {import("../components/utils/mapAgreements").PaymentFrequency} frequency
 * @returns {PaymentFrequencyNorwegian} - The frequency in Norwegian.
 *
 * @example
 * const result = convertFrequencyFromAgreementNameToNorwegian("YEARLY")
 */
export const convertFrequencyFromAgreementNameToNorwegian = (frequency) => {
  switch (frequency) {
    case "YEARLY":
      return "AARLIG"
    case "BIANNUALLY":
      return "HALVAARLIG"
    case "QUARTERLY":
      return "KVARTALVIS"
    case "TRIANNUALLY":
      return "TRE_GANGER_I_AARET"
    case "BIMONTHLY":
      return "BIMAANEDLIG"
    case "MONTHLY":
      return "MAANEDLIG"
    default:
      return "KVARTALVIS"
  }
}

// This function parses the subsidy effect data from api-gateway
// to the format needed for mappedStateAid in caseMemo
/**
 * Calculates the sum of two numbers.
 *
 * @param {CaseMemo} caseMemo - The caseMemo object which contains mappedStateAid.
 * @param {[
 *  {
 *   deliveryId: string,
 *   subsidyEffect: object,
 *   netPresentValue: number
 *  }
 * ]} deliverySubsidyEffects - The calculated subsidy effects containing deliveryId, subsidyEffect and netPresentValue.
 * @returns {object} - The new `mappedStateAid` with newly calculated `subsidyEffects`.
 * @property {array} activities - The updated activities with new calculated subsidy effect.
 * @property {Product[]} products - The updated products with new calculated subsidy effect.
 * @property {object} ...rest - The old values that are not overwritten.
 */
export const parseSubsidyEffectForDelivery = (
  caseMemo,
  deliverySubsidyEffects // updated mappedStateAid
) => {
  const products = caseMemo?.mappedStateAid?.products

  // Map subsidy effect to correct product in activities
  const updatedActivitiesProducts = caseMemo?.mappedStateAid?.activities?.map(
    (activity) => {
      // Check if current activity has products, and that product matches delivery
      const productsInActivity = activity?.products?.map((product) => {
        const newCalculation = deliverySubsidyEffects?.find(
          (delivery) => delivery?.deliveryId === product?.product
        )
        return {
          ...product,
          subsidyEffect:
            newCalculation?.subsidyEffect && newCalculation.subsidyEffect > 0
              ? newCalculation?.subsidyEffect
              : 0,
        }
      })
      return {
        ...activity,
        products: productsInActivity,
      }
    }
  )
  const newProducts = products?.map((product) => {
    const newCalculation = deliverySubsidyEffects?.find(
      (delivery) => delivery?.deliveryId === product?.productId
    )
    if (!newCalculation) return product

    const { calculatedFields } = product
    const { subsidyEffect, fundingIntensity, supportBasis } = calculatedFields

    let newCalculatedSubsidyEffect = 0

    if (
      typeof newCalculation?.subsidyEffect === "number" &&
      newCalculation?.subsidyEffect > 0
    ) {
      newCalculatedSubsidyEffect = newCalculation?.subsidyEffect
    } else if (newCalculation?.subsidyEffect <= 0) {
      newCalculatedSubsidyEffect = 0
    } else if (subsidyEffect) {
      newCalculatedSubsidyEffect = subsidyEffect
    }

    const newCalculatedFields = {
      fundingIntensity:
        newCalculation?.subsidyEffect?.fundingIntensity ?? fundingIntensity,
      subsidyEffect: newCalculatedSubsidyEffect,
      supportBasis: newCalculation?.subsidyEffect?.supportBasis ?? supportBasis,
    }

    const productRows = product?.rows?.map((row) => {
      return {
        ...row,
        subsidyEffect: newCalculatedSubsidyEffect,
      }
    })

    return {
      ...product,
      rows: productRows,
      calculatedFields: {
        ...newCalculatedFields,
      },
    }
  })
  const newProductsSubsidyCalculated = newProducts?.map((stateAidProduct) => ({
    ...stateAidProduct,
    calculatedFields: {
      fundingIntensity: stateAidProduct.rows.reduce(
        (acc, { fundingIntensity }) => acc + +fundingIntensity,
        0
      ),
      subsidyEffect: stateAidProduct.rows.reduce(
        (acc, { subsidyEffect }) => acc + +subsidyEffect,
        0
      ),
      supportBasis: stateAidProduct.rows.reduce(
        (acc, { supportBasis }) => acc + +supportBasis,
        0
      ),
    },
  }))
  return {
    ...caseMemo?.mappedStateAid,
    activities: updatedActivitiesProducts,
    products: newProductsSubsidyCalculated,
  }
}

/**
 * This function calculates the subsidy effect for the delivery.
 * @param {InFlow.Delivery} delivery - The delivery object.
 * @param {CaseOverview} caseOverview - The case overview object.
 * @param {SubsidyEffectData} subsidyEffectData - The subsidy effect data.
 * @param {SecurityRiskData[]} securityRiskData - The security risk data.
 * @param {OperationalRisk} selectedOperationalRisk - The selected operational risk.
 *
 * @returns {SubsidyEffectParams} - The subsidy effect params.
 */
export const getSubsidyEffectParamsForDelivery = (
  delivery,
  caseOverview,
  subsidyEffectData,
  securityRiskData,
  selectedOperationalRisk
) => {
  const { terminGebyr, referanseRente, establishmentFeePercentage } =
    subsidyEffectData || {}

  const {
    gracePeriod,
    interestFreePeriod,
    maturity,
    loanType,
    paymentInformation,
    interestProfile,
  } = delivery?.agreement?.data?.specificAccountTypeInfo || {}
  const lopetid = maturity?.numberOfMonths / 12
  const avdragsFriPeriode = gracePeriod?.numberOfMonths / 12
  const antallRenteFrieAar = interestFreePeriod?.numberOfMonths / 12
  const avdragsPeriode = lopetid - avdragsFriPeriode
  const frekvensFromAgreement = convertFrequencyFromAgreementNameToNorwegian(
    paymentInformation?.paymentFrequency
  )
  const { baseRate, marginRate = {} } =
    interestProfile?.interestLine?.interestRates
  const nominellRenteFromAgreement =
    (baseRate?.value + (marginRate?.value ?? 0)) / 100

  const terminerPerAar = convertFrequencyToNumber(frekvensFromAgreement)
  const driftsRisiko = trimOperationalRisk(
    selectedOperationalRisk || caseOverview?.selectedOperationalRisk?.title
  )

  // Find the risk item that matches the delivery ID
  const matchingRiskItem = securityRiskData?.find(
    (riskItem) => riskItem?.deliveryId === delivery?.deliveryId
  )

  const sikkerhetsRisiko = matchingRiskItem
    ? Number(matchingRiskItem.safetyRisk)
    : undefined

  /**
   * @type {LoanType} - The loan type.
   */
  const laaneType = loanType === "SERIAL" ? "SERIELAAN" : "ANNUITETSLAAN"

  const subsidyEffectParams = {
    terminerPerAar: terminerPerAar,
    terminGebyr,
    antallAar: avdragsPeriode,
    antallAvdragsFrieAar: avdragsFriPeriode,
    antallRenteFrieAar,
    nominellRente: nominellRenteFromAgreement,
    referanseRente: referanseRente / 100,
    sikkerhetsRisiko: sikkerhetsRisiko || -1,
    driftsRisiko,
    utbetalingsKurs: (100 - establishmentFeePercentage) / 100,
    laaneType,
    frekvens: frekvensFromAgreement,
    risikopaaslag: 0,
    deliveryId: delivery?.deliveryId,
  }
  return subsidyEffectParams
}

/**
 * @typedef {"SERIELAAN" | "ANNUITETSLAAN"} LoanType - The loan type.
 */

/**
 * @typedef {object} SubsidyEffectParams - The subsidy effect params.
 * @property {number} terminerPerAar - The number of terms per year.
 * @property {number} terminGebyr - The term fee.
 * @property {number} antallAar - The number of years.
 * @property {number} utbetalingsKurs - The payment rate.
 * @property {number} antallAvdragsFrieAar - The number of years with no repayments.
 * @property {number} antallRenteFrieAar - The number of years with no interest.
 * @property {number} nominellRente - The nominal interest rate.
 * @property {number} referanseRente - The reference rate.
 * @property {number} sikkerhetsRisiko - The security risk.
 * @property {OperationalRisk} driftsRisiko - The operational risk.
 * @property {LoanType} laaneType - The loan type.
 * @property {string} frekvens - The frequency.
 * @property {number} risikopaaslag - The risk surcharge.
 * @property {string} deliveryId - The id of the product / loan / delivery.
 * @property {boolean} [hasUpsideFee] - The upside fee.
 */

/**
 * @typedef {"A" | "B" | "C" | "D"} OperationalRisk - The operational risk.
 */

/**
 * @typedef {object} CaseOverview - The case overview object.
 * @property {object} selectedOperationalRisk - The selected operational risk.
 * @property {OperationalRisk} selectedOperationalRisk.title - The title of the selected operational risk.
 */

/**
 * @typedef {object} SubsidyEffectData - The subsidy effect data.
 * @property {LoanType} laaneType
 * @property {string} saldoDato - The balance date.
 * @property {string} datoForsteInnbetaling - The first payment date.
 * @property {object} lopetid - The term.
 * @property {number} lopetid.aar - The term in years.
 * @property {number} lopetid.maaneder - The term in months.
 * @property {string} renteType - The interest type. Eg. "TYPE_30_360".
 * @property {PaymentFrequencyNorwegian} frekvens - The frequency.
 * @property {number} nominellRente - The nominal interest rate. For example 8.2.
 * @property {number} laanebelop - The loan amount.
 * @property {number} terminGebyr - The term fee.
 * @property {number} etableringsGebyr - The establishment fee.
 * @property {number} depotGebyr - The depot fee.
 * @property {number} tinglysningsGebyr - The registration fee.
 * @property {object[]} betalingsfriPeriode - The interest free period.
 * @property {string} betalingsfriPeriode.fromDate - The start date of the interest free period.
 * @property {object} betalingsfriPeriode.lopetid - The term of the interest free period.
 * @property {number} betalingsfriPeriode.lopetid.aar - The term in years.
 * @property {number} betalingsfriPeriode.lopetid.maaneder - The term in months.
 * @property {boolean} betalingsfriPeriode.rentefri - The interest free period.
 * @property {object[]} avdragsfriPeriode - The repayment free period.
 * @property {string} avdragsfriPeriode.fromDate - The start date of the repayment free period.
 * @property {object} avdragsfriPeriode.lopetid - The term of the repayment free period.
 * @property {number} avdragsfriPeriode.lopetid.aar - The term in years.
 * @property {number} avdragsfriPeriode.lopetid.maaneder - The term in months.
 * @property {number} referanseRente - The reference rate.
 * @property {number} establishmentFeePercentage - The establishment fee percentage.
 * @property {number} establishmentFee - The establishment fee.
 * @property {number} risikopaaslag - The risk surcharge.
 * @property {number} [upsideFeePercentage] - the oppsidegebyr / upside fee percentage. This is new property, might be undefined for older cases
 */

/**
 * @typedef {object} SecurityRiskData - The security risk data.
 * @property {string} deliveryId - The delivery id.
 * @property {number} safetyRisk - The safety risk.
 */

/**
 * @typedef {object} Delivery - The delivery object.
 * @property {import("../components/utils/mapAgreements").CollateralEngagement} agreement - The agreement object.
 * @property {string} deliveryId - The delivery id.
 */

/**
 * @typedef {object} Product - The product object.
 * @property {string} productId - The product id.
 * @property {string} productName - The product name.
 * @property {string} productType - The product type.
 * @property {string} productSubType - The product sub type.
 * @property {object} calculatedFields - The calculated fields.
 * @property {number} calculatedFields.fundingIntensity - The funding intensity.
 * @property {number} calculatedFields.subsidyEffect - The subsidy effect.
 * @property {number} calculatedFields.supportBasis - The support basis.
 * @property {object[]} rows - The rows.
 */

/**
 * @typedef {import("../components/evaluateBasisForSupport/mapCaseInput").Activity} Activity - The activity object.
 */

/**
 * @typedef {object} CaseMemo - The case memo object.
 * @property {object} mappedStateAid - The mapped state aid.
 * @property {Activity[]} mappedStateAid.activities - The activities.
 * @property {Product[]} mappedStateAid.products - The products.
 */

/**
 * @typedef {object} TableDiff - The table loan object.
 * @property {number} saldo - The balance.
 * @property {number} termin - The term.
 * @property {number} rente - The interest.
 * @property {number} avdrag - The repayment.
 * @property {number} kontantstrøm - The cash flow.
 * @property {number} terminBeløp - The term amount.
 * @property {number} gebyr - The fee.
 * @property {number} utbetalt - The paid.
 * @property {number} terminRente - The term interest.
 * @property {number} differanseKontantStrøm - The cash flow difference.
 * @property {number} referanseKontantStrøm - The reference cash flow.
 * @property {number} låneKontantStrøm - The loan cash flow.
 * @property {number} låneRenter - The loan interest.
 * @property {number} referanseRenter - The reference interest.
 *
 * @typedef {object} TableReferenceLoan - The table reference loan object.
 * @property {number} termin - The term.
 * @property {number} saldo - The balance.
 * @property {number} utbetalt - The paid.
 * @property {number} rente - The interest.
 * @property {number} avdrag - The repayment.
 * @property {number} kontantstrøm - The cash flow.
 * @property {number} terminBeløp - The term amount.
 * @property {number} gebyr - The fee.
 * @property {number} terminRente - The term interest.
 *
 * @typedef {object} TableLoan - The table loan object.
 * @property {number} termin - The term.
 * @property {number} saldo - The balance.
 * @property {number} utbetalt - The paid.
 * @property {number} rente - The interest.
 * @property {number} avdrag - The repayment.
 * @property {number} kontantstrøm - The cash flow.
 * @property {number} terminBeløp - The term amount.
 * @property {number} gebyr - The fee.
 * @property {number} terminRente - The term interest.
 */
