import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { TextArea } from "@flow/forms"
import PayoutDetails from "./detailsComponents/PayoutDetails"
import React, { useState } from "react"
import CheckList from "./detailsComponents/CheckList"
import { FontStyles } from "@flow/style"
import InformationLine from "./detailsComponents/InformationLine"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import formatWithLocale from "../../util/dateFormatter"
import RemainingAmount from "./detailsComponents/RemainingAmount"
import Shortening from "./detailsComponents/Shortening"
import { Colors } from "@flow/style"
import GridContent from "./detailsComponents/GridContent"
import { Select } from "@flow/forms"
import { getCurrency } from "../../util/getCurrency"
import GuaranteeDetails from "./detailsComponents/GuaranteeDetails"

/**
 * The details component
 * @param {object} props The props
 * @param {object} props.flow The flow
 * @param {Function} props.t Translation function
 * @param {InFlow.StateData} props.stateData The state data
 * @param {Function} props.setStateData The function that sets the state data
 * @param {Boolean} props.readonly If the component is readonly
 * @param {Function} props.partialSave The function that saves the data
 * @param {object} props.validationError The validation error
 * @param {String} props.deliveryId The delivery id
 * @param {InFlow.Payment} props.payment The payment
 * @param {Boolean} props.isPerformFinancialCheck If the user is performing a financial check
 * @returns {JSX.Element} The details component
 */
const Details = ({
  flow,
  t,
  stateData,
  setStateData,
  readonly,
  partialSave,
  validationError,
  deliveryId,
  payment,
  isPerformFinancialCheck,
}) => {
  const [comment, setComment] = useState(stateData.comment)

  /**
   * @type {InFlow.Delivery}
   */
  const currentDelivery = flow?.data?.deliveries[deliveryId]
  const isGuarantee = currentDelivery.productClass === "Guarantee"

  const { payoutRequestReceived, commitment } =
    currentDelivery.currentPayout || {}
  const account = commitment?.accountByAccountName
  const rawAccount = currentDelivery.createAccount.body
  const grantedAmount = currentDelivery?.amount
  const karCheckMessage =
    currentDelivery?.currentPayout?.karCheck?.resultText || ""

  const flowId = flow?.flowId

  const totalPayoutAmount = stateData?.payments?.reduce((acc, payment) => {
    return acc + (Number(payment?.payoutAmount) || 0)
  }, 0)

  stateData.totalPayoutAmount = totalPayoutAmount
  let remainingAmount
  if (totalPayoutAmount !== payoutRequestReceived.payoutAmount) {
    remainingAmount =
      Number(commitment?.accountByAccountName?.availableAmount) -
      Number(totalPayoutAmount)
  } else {
    remainingAmount =
      Number(commitment?.accountByAccountName?.availableAmount) -
      Number(payoutRequestReceived.payoutAmount)
  }
  stateData.remainingAmount = remainingAmount
  const handleInputChange = (e) => {
    const newData = {
      ...stateData,
      comment: e.target.value,
    }
    setComment(e.target.value)
    setStateData(newData)
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  const onChangePayoutType = (e) => {
    if (!readonly) {
      setStateData({
        ...stateData,
        payoutType: e.target.value,
      })
    }
  }

  const options = [
    { label: t("partialPayout"), value: "partialPayout" },
    { label: t("advancePayout"), value: "advancePayout" },
    { label: t("finalPayout"), value: "finalPayout" },
  ]
  const submitter = payoutRequestReceived?.submittedForm?.submitter

  const hasSwiftNumber = Boolean(payoutRequestReceived?.content?.swiftNumber)
  const hasIbanNumber = Boolean(payoutRequestReceived?.content?.ibanNumber)
  const currency = getCurrency(flow?.data?.application?.category)

  return (
    <Content>
      <Row>
        <LabelSpan>{t("info-from-payout-request")}</LabelSpan>
        <BackGround>
          <InformationLine
            data={[
              ...(isGuarantee
                ? [
                    {
                      title: t("name-of-bank"),
                      value: currentDelivery?.guarantee?.nameOfBank,
                    },
                  ]
                : [
                    {
                      title: t("account-number"),
                      value: payoutRequestReceived?.customerAccountNumber,
                    },
                  ]),
              ...(!isGuarantee && hasSwiftNumber
                ? [
                    {
                      title: "Swift",
                      value: payoutRequestReceived?.content?.swiftNumber,
                    },
                  ]
                : []),
              ...(!isGuarantee && hasIbanNumber
                ? [
                    {
                      title: "IBAN",
                      value: payoutRequestReceived?.content?.ibanNumber,
                    },
                  ]
                : []),
              {
                title: t(
                  isGuarantee ? "guarantee-amount-value" : "payout-amount"
                ),
                value:
                  addThousandSeparator(payoutRequestReceived?.payoutAmount) +
                  ` ${currency}`,
              },
              {
                title: t("payout-type"),
                value: t(payoutRequestReceived?.payoutType),
              },
              {
                title: t("payout-contact-name"),
                value: [submitter?.name, submitter?.roleNames].join(", "),
              },
              {
                title: t("payout-contact-email"),
                value: submitter?.email,
              },
              {
                title: t("payout-contact-phone"),
                value: submitter?.phone,
              },
            ]}
          />
          <DescriptionRow>
            <LabelSpan>{t("description")}</LabelSpan>
            <LabelSpan>{payoutRequestReceived?.content?.feedback}</LabelSpan>
          </DescriptionRow>
        </BackGround>
      </Row>
      <Row>
        <LabelSpan>{t("info-from-coreView")}</LabelSpan>
        <InformationLine
          data={
            isGuarantee
              ? [
                  { title: t("acno-agno"), value: account?.accountName },
                  { title: t("Status"), value: account?.coreViewStateName },
                  {
                    title: t("guarantee-maturity"),
                    value: `${
                      ((rawAccount?.totalPeriods ?? 0) *
                        (rawAccount?.periodMonths ?? 0)) /
                      12
                    } år`,
                  },
                  {
                    title: t("guarantee-amount-value"),
                    value: `${addThousandSeparator(
                      account?.totalAmount ?? 0
                    )} ${currency}`,
                  },
                  {
                    title: t("name-of-bank"),
                    value: currentDelivery?.guarantee?.nameOfBank,
                  },
                ]
              : [
                  { title: t("acno-agno"), value: account?.accountName },
                  { title: t("Status"), value: account?.coreViewStateName },
                  {
                    title: t("amount-available"),
                    value:
                      addThousandSeparator(account?.availableAmount) +
                      ` ${currency}`,
                  },
                  {
                    title: t("amount-approved"),
                    value: addThousandSeparator(grantedAmount) + ` ${currency}`,
                  },
                  {
                    title: t("amount-paid"),
                    value:
                      addThousandSeparator(Math.abs(account?.payoutAmount)) +
                      ` ${currency}`,
                  },
                  {
                    title: t("last-possible-payout-date"),
                    value:
                      account?.expiryDate &&
                      formatWithLocale(account?.expiryDate, "dd.MM.yyyy"),
                  },
                ]
          }
        />
      </Row>
      {isGuarantee ? (
        <>
          <LabelSpanRealRow>{t("guarantee-details")}</LabelSpanRealRow>
          <RealRow>
            <GuaranteeDetails
              t={t}
              currentDelivery={currentDelivery}
              stateData={stateData}
              setStateData={setStateData}
              readonly={readonly}
            />
          </RealRow>
        </>
      ) : (
        <>
          <LabelSpanRealRow>{t("payout-details")}</LabelSpanRealRow>
          <RealRow>
            <InformationLine
              data={[
                {
                  title: t("share-payment"),
                  value:
                    (
                      (Number(totalPayoutAmount) /
                        Number(currentDelivery?.amount)) *
                      100
                    ).toFixed(2) + " %",
                },
              ]}
            />
            <InformationLine
              data={[
                {
                  title: t("totalPayoutAmount"),
                  value:
                    addThousandSeparator(totalPayoutAmount) + ` ${currency}`,
                },
              ]}
            />
            <Margins>
              <RemainingAmount
                commitment={commitment}
                t={t}
                stateData={stateData}
                payoutRequestReceived={payoutRequestReceived}
                remainingAmount={remainingAmount}
                currency={currency}
              />
              {stateData?.payoutType === "finalPayout" &&
                remainingAmount > 0 && (
                  <Shortening
                    t={t}
                    text={t("shortening-text")}
                    props={{ color: Colors.Ferrari }}
                  ></Shortening>
                )}
            </Margins>
            <div>
              <MarginsTwo>
                {!readonly ? (
                  <>
                    <Select
                      showBlankOption={true}
                      blankOptionLabel={t("choose")}
                      id="payoutType"
                      value={stateData?.payoutType}
                      options={options}
                      disabled={readonly}
                      label={t("payout-type")}
                      onChange={onChangePayoutType}
                    />

                    {validationError?.payoutType && (
                      <ErrorField> {validationError?.payoutType}</ErrorField>
                    )}
                  </>
                ) : (
                  <GridContent
                    title={t("payout-type")}
                    data={t(`${stateData?.payoutType}`)}
                    contentProps={{ color: Colors.Coal }}
                  />
                )}
              </MarginsTwo>
            </div>
          </RealRow>
          <Row>
            <LabelSpan>{t("details-payout")}</LabelSpan>
            <PayoutDetails
              deliveryId={deliveryId}
              karCheckMessage={karCheckMessage}
              flowId={flowId}
              payoutRequestReceived={payoutRequestReceived}
              currentDelivery={currentDelivery}
              readonly={readonly}
              stateData={stateData}
              t={t}
              partialSave={partialSave}
              setStateData={setStateData}
              validationError={validationError}
              flow={flow}
              isPerformFinancialCheck={isPerformFinancialCheck}
            />
          </Row>
        </>
      )}
      <Row>
        <CheckList
          stateData={stateData}
          readonly={readonly}
          setStateData={setStateData}
          t={t}
        />
      </Row>
      <Row>
        <LabelSpan>{t("internal-comments")}</LabelSpan>
        <TextWrapper>
          <TextAreaBox
            disabled={readonly}
            label={t("comment")}
            help={t("internal-comment-help-financial-control")}
            value={comment}
            onChange={(e) => handleInputChange(e)}
            name="internal-comment"
          />
        </TextWrapper>
      </Row>
    </Content>
  )
}
export default withTranslation()(Details)
const LabelSpan = styled.div`
  ${FontStyles.Normal};
  margin-bottom: 1rem;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40em;
`

const TextWrapper = styled.div`
  margin-top: 0.5em;
`

const TextAreaBox = styled(TextArea)`
  width: 100%;
  height: 150px;
`

const Row = styled.div`
  justify-content: flex-start;
  margin-top: 1em;
  position: relative;
`
const RealRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
  grid-gap: 1em;
  grid-column-gap: 1em;
  border-radius: 8px;
  background-color: ${Colors.SeaLighter};
`
const DescriptionRow = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  grid-column-gap: 1em;
  background-color: ${Colors.SeaLighter};
  padding: 1rem;
  border-radius: 15px;
`
const LabelSpanRealRow = styled.div`
  ${FontStyles.Normal};
  margin: 1rem 0px 1rem 0px;
`
const Margins = styled.div`
  margin-top: 1.5em;
`
const MarginsTwo = styled.div`
  margin-top: 1.2em;
`

const BackGround = styled.div`
  background-color: ${Colors.SeaLighter};
  border-radius: 15px;
`
const ErrorField = styled.span`
  color: ${Colors.Ferrari};
`
