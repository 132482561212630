import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../../components/common/Layout"
import ReactForm from "../../components/common/ReactForm"
import { saveAs } from "file-saver"
import { Colors } from "@flow/style"
import { Icons } from "@flow/icons"
import WarningInfoBox from "../../components/common/WarningInfoBox"
import Notice from "../../components/common/Notice"
import ErrorText from "../../components/common/ErrorText"

const FileButton = ({ string, title, filename, buttonHeight = "40px" }) => {
  const file = new Blob([string], { type: "application/pdf" })

  return (
    <ApplicationBtnDiv buttonHeight={buttonHeight}>
      <SecondaryButton disabled={false} onClick={() => saveAs(file, filename)}>
        <ContentBtnDiv>
          <Icons.FilePdf color={Colors.Grey3} />
          <div className="content-btn-div-children">{title}</div>
        </ContentBtnDiv>
      </SecondaryButton>
    </ApplicationBtnDiv>
  )
}

const ContentBtnDiv = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 10px;

  .content-btn-div-children {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ApplicationBtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  button {
    height: ${(props) => props.buttonHeight};
  }
`

const ApproveCSVData = (props) => {
  const [formData, setFormData] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const [validations, setValidations] = useState([])

  const { flow, t, task, schema } = props

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleComplete = (values) => {
    setIsProcessing(true)
    props.complete(
      {
        ...formData,
      },
      () => {
        setIsProcessing(false)
      },
      (err) => {
        if (err.errorType === "handlerValidation") {
          setValidations(err.errors?.map((x) => x.message) ?? ["Unknown error"])
        }
        setIsProcessing(false)
      }
    )
  }
  const handleSave = () => {
    setIsProcessing(true)
    props.save(
      {
        ...formData,
      },
      () => setIsProcessing(false),
      () => {
        console.error("Could not save task")
        setIsProcessing(false)
      }
    )
  }

  const { csvData, ...context } = task.context

  return (
    <Layout forceHeight>
      <MainContainer>
        <FileButton
          string={csvData}
          title={t("download-file")}
          filename={`årsgjennomgang-${flow.data.input.year}.csv`}
        />
      </MainContainer>

      <Context flow={flow} context={context}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onSubmit={handleComplete}
          onChange={(values) => onFormChange(values)}
        >
          {validations &&
            validations.map((x, i) => (
              <ErrorContainer>
                <ErrorText error={x} t={t}></ErrorText>
              </ErrorContainer>
            ))}
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const ErrorContainer = styled.div`
  background-color: #fbeceb;
  padding: 0.75em;
  margin-top: 10px;
  border-radius: 4px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const MainContainer = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
  padding: 2em;
`

const Content = styled(Notice)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.OrangeLighter};
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

export default withTranslation()(ApproveCSVData)
