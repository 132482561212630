import { v4 as uuidv4 } from "uuid"
import {
  DECISION_TEXTS,
  formatStakeholderNames,
  generateAccumulateInterestChangeTexts,
  generateDeleteElementChangeTexts,
  generateGracePeriodChangeTexts,
  generateInterestFreePeriodChangeTexts,
  generatePaymentDeferralChangeTexts,
  generatePriorityChangesChangeTexts,
  generateReducedNominalAmountTexts,
  generateRefinancingChangeTexts,
} from "./maintenanceDecisionTexts"
import lodash from "lodash"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import formatWithLocale from "../../util/dateFormatter"

export const getTglDate = (change, agreements) => {
  let tglDate
  change.changeData.forEach((x) => {
    const agreement = agreements.find(
      (agreement) => agreement.internalId === x.agreementData.internalId
    )

    tglDate =
      agreement.data?.specificCollateralAgreementInformation
        ?.mortgageDeedDetails?.registeredDate
  })
  return formatWithLocale(tglDate, "dd.MM.yyyy")
}
export const mapChanges = (
  agreementregister,
  existingCollateralFromCoreview,
  delivery
) => {
  const copiedDelivery = lodash.cloneDeep(delivery)

  agreementregister.originalAgreementData.forEach((agreement) => {
    const existingAgreement = existingCollateralFromCoreview.find(
      (collateral) => collateral.internalId === agreement.internalId
    )

    const updatedAgreement = agreementregister.agreementData.find(
      (curr) => curr.internalId === agreement.internalId
    )

    // Merge together existing collaterals that we retrieved mapped to state earlier in map-engagement-fetch... together with the agreement from Insight
    // We do this, because we have more data on the agreement on state, than Insight has in their database.
    const mergedAgreement = lodash.merge(
      agreement,
      existingAgreement,
      updatedAgreement
    )

    // An array of all changes that happened to the same agreement.
    const changes = agreementregister.changesData
      .filter(
        (change) =>
          change.data.basicChangeInformation.parentId === agreement.internalId
      )
      .map((change) => {
        return {
          ...change,
          agreementData: mergedAgreement,
        }
      })

    changes.forEach((changeData) => {
      // See if we can find an existing change that contains the changeData we're currently looking at.
      // If that change exists, we don't want to do anything, as it's already been added previously.
      const existingChange = copiedDelivery.changes.find((change) => {
        if (
          change &&
          change.changeData?.find((c) => c.internalId === changeData.internalId)
        ) {
          return true
        }
        return false
      })

      if (existingChange) {
        // Update change data on existingChange
        const existingChangeDataIndex = existingChange.changeData?.findIndex(
          (c) => c.internalId === changeData.internalId
        )

        // //Update collateral coverages
        // const updatedCollateralCoverages =
        //   changeData.data.collateralCoverages.filter(
        //     (coverage) => coverage.changeStatus !== "DELETED"
        //   )

        // const collateralCoverages = existingChange.changeData[
        //   existingChangeDataIndex
        // ].agreementData.data.basicCollateralAgreementInformation.collateralCoverages.filter(
        //   (coverage) => {
        //     const existingItem = updatedCollateralCoverages.find(
        //       (x) => x.internalId === coverage.internalId
        //     )

        //     if (existingItem) {
        //       return coverage
        //     }
        //   }
        // )

        existingChange.changeData[existingChangeDataIndex] = changeData
        // existingChange.changeData[
        //   existingChangeDataIndex
        // ].agreementData.data.basicCollateralAgreementInformation.collateralCoverages =
        //   collateralCoverages
      } else {
        // If the change doesn't exist, but there is a change with the same type, we add the changeData to the existing change.
        const changeOfSameType = copiedDelivery.changes.find((change) => {
          if (change && change?.type === changeData.type) {
            return change.changeData.some((cData) => {
              if (
                cData.data.basicChangeInformation.parentId ===
                changeData.data.basicChangeInformation.parentId
              ) {
                return true
              }
              return false
            })
          }
          return false
        })

        if (changeOfSameType) {
          changeOfSameType.changeData.push(changeData)
        } else {
          const uuid = uuidv4()
          copiedDelivery.changes.push({
            id: uuid,
            type: changeData.type,
            changeData: [changeData],
            decisionTexts: {
              userAdded: [],
              generated: [],
            },
          })
        }
      }
    })
  })

  /**
   * Run through all changes, and all the changes data, if one of the changeData's don't exist in the agreementRegister, remove it.
   * The reason they won't exist, is if you add two changes to a single loan, go into "Vedtakstekster" go back to "Sikkerheter" remove the change, and go back again
   * It would previously display the change as still existing.
   */
  copiedDelivery.changes.forEach((deliveryChange) => {
    if (deliveryChange.type === "MANUAL") return
    deliveryChange.changeData = deliveryChange.changeData.filter(
      (deliveryChangeData) => {
        const existsInAgreementRegister = agreementregister.changesData.some(
          (change) => change.internalId === deliveryChangeData.internalId
        )
        if (!existsInAgreementRegister) {
          return false
        }
        return true
      }
    )
  })

  // If any changes have empty changeData, than just remove the change.
  copiedDelivery.changes = copiedDelivery.changes.filter((deliveryChange) => {
    if (!deliveryChange.changeData) return false
    if (deliveryChange.changeData.length <= 0) {
      return false
    }
    return true
  })

  return copiedDelivery
}

// Add missing fields required for decision texts to the agreementregister data.
export const mapEngagementToAgreementRegister = (
  agreementregister,
  engagements
) => {
  const agreementData = agreementregister.agreementData.map((agreement) => {
    if (agreement.type === "LOAN") {
      const engagement = engagements.find(
        (e) =>
          e.accountNumber ===
          agreement.data.basicAccountInformation.accountNumber
      )
      if (engagement) {
        agreement.data.basicAccountInformation.accountBalance = {
          ...agreement.data.basicAccountInformation.accountBalance,
          availableAmount: engagement.availableAmount,
          blockedAmount: engagement.blockedAmount,
        }
      }
    }
    return agreement
  })

  agreementregister.agreementData = agreementData

  return agreementregister
}

export const addMissingDecisionFields = (delivery, changeKey) => {
  if (!delivery.changes[changeKey]) {
    delivery.changes[changeKey] = {
      decisionTexts: {
        userAdded: [],
        generated: [],
      },
    }
  }
}

export const mapAgreementsToOptions = (agreements, t) => {
  const groupedOptions = []
  agreements.forEach((agreement) => {
    const existingGroup = groupedOptions.find((e) => {
      return e.group === t(agreement.type)
    })
    let option = {}
    if (agreement.type === "LOAN") {
      option = {
        label:
          agreement.data.basicAccountInformation.accountNumber +
          " | " +
          addThousandSeparator(
            Math.abs(
              agreement.data.specificAccountTypeInfo.initialLoanInformation
                .loanAmount
            )
          ),
        value: agreement.data.internalId,
      }
    } else if (agreement.type === "SURETYSHIP") {
      const firstPartOfLabel =
        agreement.data.basicCollateralAgreementInformation
          .basicCollateralInformation.name ||
        agreement.data.basicCollateralAgreementInformation
          .basicCollateralInformation.subDescription

      option = {
        label:
          firstPartOfLabel +
          " | " +
          addThousandSeparator(
            agreement.data.specificCollateralAgreementInformation.suretyAmount
          ),
        value: agreement.data.internalId,
      }
    } else if (agreement.type === "MORTGAGE_DEED") {
      const firstPartOfLabel =
        agreement.data.basicCollateralAgreementInformation
          .basicCollateralInformation.productInformation.productName ||
        agreement.data.basicCollateralAgreementInformation
          .basicCollateralInformation.name

      option = {
        label:
          firstPartOfLabel +
          " | " +
          addThousandSeparator(
            agreement.data.specificCollateralAgreementInformation
              .mortgageDeedDetails.faceValue
          ),
        value: agreement.data.internalId,
      }
    } else {
      option = {
        label: "Unknown agreement type",
        value: agreement.data.internalId,
      }
    }
    if (existingGroup) {
      // Append to existing group
      existingGroup.options.push(option)
    } else {
      // Create new group
      groupedOptions.push({
        group: t(agreement.type),
        options: [option],
      })
    }
  })
  return groupedOptions
}

// Replaces example: {0} in the string with the first argument in the function.
const formatString = (string, ...args) => {
  return string.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] == "undefined" ? match : args[index]
  })
}

export const generateDecisionText = (
  change,
  context,
  t,
  insightMappedStakeholder,
  agreements
) => {
  const customerName = context.applicationSummary?.applicant?.name
  const collateralType =
    change.changeData[0].agreementData.data?.basicCollateralAgreementInformation
      ?.basicCollateralInformation?.name // We just take the first from changeData, as there currently is always just one, changeData is an array in case we ever need it to have multiple.

  const mortgagors =
    change.changeData[0].agreementData.data?.basicCollateralAgreementInformation
      ?.mortgagors || []

  const currentAgreementAccountNumber =
    change.changeData[0].agreementData.data?.basicAccountInformation
      ?.accountNumber
  let text = ""
  let title = t(change.type)
  let allowModification = false // If true, the decision text is allowed to be modified by the user.

  if (change.type === "GRACEPERIOD") {
    text = formatString(
      DECISION_TEXTS.GRACEPERIOD,
      customerName,
      generateGracePeriodChangeTexts(change)
    )
  } else if (change.type === "DELETEELEMENT") {
    text = formatString(
      DECISION_TEXTS.DELETEELEMENT,
      customerName,
      generateDeleteElementChangeTexts(change, insightMappedStakeholder)
    )
  } else if (change.type === "PRIORITYCHANGES") {
    text = formatString(
      DECISION_TEXTS.PRIORITYCHANGES,
      generatePriorityChangesChangeTexts(change, insightMappedStakeholder)
    )
  } else if (change.type === "INTERESTFREEPERIOD") {
    text = formatString(
      DECISION_TEXTS.INTERESTFREEPERIOD,
      generateInterestFreePeriodChangeTexts(change)
    )
  } else if (change.type === "PAYMENTDEFERRAL") {
    text = formatString(
      DECISION_TEXTS.PAYMENTDEFERRAL,
      generatePaymentDeferralChangeTexts(change)
    )
  } else if (change.type === "REFINANCING") {
    text = formatString(
      DECISION_TEXTS.REFINANCING,
      generateRefinancingChangeTexts(
        change,
        agreements,
        currentAgreementAccountNumber
      ),
      formatWithLocale(
        change.changeData[0].data.refinancing.nextDueDate,
        "dd.MM.yyyy"
      ), // We just take the first from changeData, as there currently is always just one, changeData is an array in case we ever need it to have multiple.
      currentAgreementAccountNumber
    )
    allowModification = true
  } else if (change.type === "ACCUMULATEINTEREST") {
    text = formatString(
      DECISION_TEXTS.ACCUMULATEINTEREST,
      customerName,
      generateAccumulateInterestChangeTexts(change)
    )
  } else if (change.type === "REDUCEDNOMINALAMOUNT") {
    text = formatString(
      DECISION_TEXTS.REDUCEDNOMINALAMOUNT,
      collateralType,
      formatStakeholderNames(insightMappedStakeholder, mortgagors),
      addThousandSeparator(
        change.changeData[0].agreementData.data
          .specificCollateralAgreementInformation.mortgageDeedDetails.faceValue
      ),
      getTglDate(change, agreements),
      change.changeData[0].agreementData.data
        .specificCollateralAgreementInformation.mortgageDeedDetails
        .registrationNumber,
      generateReducedNominalAmountTexts(change)
    )
  } else if (change.type === "COLLATERALCOVERAGES") {
    return
  } else {
    title = "Ukjent type endring"
    text = "Dette er en ukjent type endring, legg til en manuell vedtakstekst."
  }

  return {
    title,
    text,
    allowModification,
  }
}
const taskDecisionLookup = {
  reskontro: "manually-update-core",
  customer: "do-customer-followup",
}

const parseComment = (comment, type, t) => {
  const taskDecision = taskDecisionLookup[type] || "default"

  return {
    date: comment.timestamp,
    comment: comment.comment,
    user: comment?.user,
    task: t(taskDecision),
    decision:
      type === "customer" ? t("needs-update-in-core") : t("follow-up-customer"),
  }
}

export const parseCommentsForAssessment = (context, t) => {
  const coreComments = context?.manuallyUpdateCoreComments || []
  const customerComments = context?.doCustomerFollowUpComments || []

  const parsedCoreComments = coreComments.map((comment) =>
    parseComment(comment, "reskontro", t)
  )
  const parsedCustomerComments = customerComments.map((comment) =>
    parseComment(comment, "customer", t)
  )
  const sorted = [...parsedCoreComments, ...parsedCustomerComments]?.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
  return sorted
}

// Copied from Change.jsx, should maybe fix title and import this to Change.jsx also
export const getTitle = (change, t) => {
  let title = ""

  if (change.type === "MANUAL") {
    title = getManualTitle(change, t)
  } else {
    title = getGeneratedTitle(change, t)
  }

  return title
}

const getManualTitle = (change, t) => {
  let title = "Manuelt lagt til | "
  change?.changeData?.forEach((agreement) => {
    if (agreement.agreementData.type === "LOAN") {
      // Loan object
      title =
        title +
        `${agreement.agreementData.data.basicAccountInformation.accountNumber} | `
    } else {
      // Collateral object
      title =
        title +
        `${agreement.agreementData.data.basicCollateralAgreementInformation.basicCollateralInformation.name} - ${agreement.agreementData.data.basicCollateralAgreementInformation.basicCollateralInformation.agreementNumber} | `
    }
  })
  return title
}
const getGeneratedTitle = (change, t) => {
  let title = ""
  change.changeData.forEach((c) => {
    if (c.agreementData.type === "CASE") {
      // An empty object used to add decision texts generally to the whole case
      title = title + `${t("maintenance")} `
    }

    if (c.agreementData.type === "LOAN") {
      // Loan object
      title =
        title +
        `${c.type !== null ? t(c.type) + " - " : ""} ${
          c.agreementData.data.basicAccountInformation.accountNumber
        } `
    } else {
      // Collateral object
      title =
        title +
        `${c.type !== null ? t(c.type) + " - " : ""} ${
          c.agreementData.data.basicCollateralAgreementInformation
            .basicCollateralInformation.name
        } - ${
          c.agreementData.data.basicCollateralAgreementInformation
            .basicCollateralInformation.agreementNumber
        } - ${
          c.agreementData.data.basicCollateralAgreementInformation
            .basicCollateralInformation.description
        } `
    }
  })
  return title
}

export const removeDecision = (decisionId, delivery, change) => {
  const newDelivery = lodash.cloneDeep(delivery)
  const deliveryChange = newDelivery.changes.find((c) => c.id === change.id)
  const userAdded = deliveryChange.decisionTexts.userAdded.findIndex(
    (decision) => decision.id === decisionId
  )
  const generated = deliveryChange.decisionTexts.generated.findIndex(
    (decision) => decision.id === decisionId
  )

  if (userAdded !== -1) {
    deliveryChange.decisionTexts.userAdded.splice(userAdded, 1)
  } else if (generated !== -1) {
    deliveryChange.decisionTexts.generated.splice(generated, 1)
  } else {
    console.error("Unable to find a decision text based on ID.", decisionId)
  }

  if (change.type === "MANUAL") {
    // Should we remove the whole change if there are no decisions left?
    if (
      deliveryChange.decisionTexts.userAdded.length === 0 &&
      deliveryChange.decisionTexts.generated.length === 0
    ) {
      const changeIndex = newDelivery.changes.findIndex(
        (c) => c.id === change.id
      )
      newDelivery.changes.splice(changeIndex, 1)
    }
  }

  return newDelivery
}
