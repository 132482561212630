import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  mapCaseInput,
  mapCostOverview,
  mapTotalProposed,
  shouldValidateProducts,
  validateAndShowInvalidProducts,
} from "./mapCaseInput"
import { Tabs } from "@flow/components"
import RequestedDocArchive from "../common/RequestedDocArchive"
import Layout, { Context } from "../common/Layout"
import styled from "styled-components"
import ErrorContext from "../common/Error"
import { validate } from "./validateComponent"
import EvaluateBasisForSupportComponent from "./EvaluatBasisSupportComponent"
import AssessmentComments from "../common/AssessmentComments"
import BlueDot from "../common/BlueDot"
import { returnFirstArgWithValue } from "../../util/returnValue"
import {
  AgreementProvider,
  useCreateAgreementStore,
} from "./lineItems/useProductDraft"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { categoriesConstants } from "../../util/getInsightContextForCategory"
import ProjectParticipants from "./ProjectParticipants"
import { TextArea } from "@flow/forms"
import AvailableFrames from "./AvailableFramesTab/AvailableFrames"
import { v4 as uuidv4 } from "uuid"
import lodash from "lodash"

const EvaluateBasisForSupportCommon = ({
  flow,
  task,
  t,
  save,
  complete,
  onTrigger,
  updateCase,
}) => {
  const { context } = task || {}
  const {
    applicationSummary,
    amountAppliedFor: totalAppliedFor,
    data: _data,
    agreements: _agreements,
    activityDefault,
    currency,
    stateCaseComment,
    previouslyRequestedDocuments,
    isDpv,
    productConfiguration,
    category,
    backwardsCompatibilty,
    isSingleActivity = false,
    insightComponentData,
    isForeignAccount,
  } = context || {}

  const isDni = category === categoriesConstants.DNI

  /**
   * @typedef {import("./mapCaseInput").Activity} Activity
   */

  /**
   * @type {{activities: Activity[], appliedAmountDeviation: string, agreements: Object} | null}
   */
  let inputData = returnFirstArgWithValue(task.data, _data, {
    activities: [],
    agreements: {},
    appliedAmountDeviation: "",
  })

  const agreements = returnFirstArgWithValue(
    task.data?.agreements,
    _agreements,
    {}
  )

  const [missingCosts, setMissingCosts] = useState(
    inputData.activities.filter((activity) => !activity.costs).length > 0
  )

  //Check frames
  const { showAvailableFramesTab } = flow.data.analysis
  const availableFrames = flow.data.analysis?.availableFrames

  // SECTION START
  // this section of code can be removed when backwards compatibility is no longer needed,
  // all cases past createCredit memo, created before: {{prodsetting date}}

  // switch variable&name. Because (old config did not use variable from applicationContent and will not match)
  const compileCosts = (
    isCustomActivity = false,
    activity,
    costConfiguration,
    originalCosts
  ) => {
    const res = costConfiguration.map((cost) => ({
      name: cost.name,
      appliedFor: isCustomActivity
        ? activity[`applied${cost?.name}`] || "0"
        : originalCosts[cost?.name] || "0",
      approved: activity[cost?.name] || "0",
    }))
    return res
  }

  if (missingCosts) {
    /**
     * @type {Activity[]|undefined}
     */
    const activitiesWithCosts = inputData.activities.reduce(
      /**
       * @param {Activity[]} previous
       * @param {Activity} activity
       * @param {number} index
       */
      (previous, activity, index) => {
        if (activity.costs) return previous
        const costs = compileCosts(
          activity.isCustomActivity,
          activity,
          backwardsCompatibilty.costConfiguration,
          backwardsCompatibilty?.activities[index]?.costs
        )
        const staticTotal = costs.reduce(
          (prev, cost) => prev + parseFloat(cost.appliedFor),
          0
        )

        previous.push({
          ...activity,
          activityTitle: activity.isCustomActivity
            ? activity.activityTitle
            : backwardsCompatibilty.activities[index].activityTitle,
          costs,
          staticTotal,
        })
        return previous
      },
      []
    )
    inputData = { ...inputData, activities: activitiesWithCosts }
    setMissingCosts(false)
  }

  // SECTION END

  const [caseInput, setCaseInput] = useState(inputData)
  const [caseInputClone, setCaseInputClone] = useState(caseInput)

  const [mappedInputData, mappedInputDataSet] = useState(
    mapCaseInput(caseInput, productConfiguration)
  )

  const totalProposed = useMemo(
    () => mapTotalProposed(mappedInputData?.products, productConfiguration),
    [mappedInputData?.products]
  )

  const totalApprovedCosts = useMemo(
    () =>
      caseInput?.activities?.reduce((acc, curr) => {
        const approved = curr.costs.reduce(
          (prev, cost) => prev + parseFloat(cost.approved),
          0
        )
        return acc + approved
      }, 0),
    [caseInput]
  )

  const totalAppliedForCosts = useMemo(
    () =>
      caseInput?.activities?.reduce((acc, curr) => {
        const approved = curr.costs.reduce(
          (prev, cost) => prev + parseFloat(cost.appliedFor),
          0
        )
        return acc + approved
      }, 0),
    [caseInput]
  )

  const [index, setIndex] = useState(0)
  const [clearForm, setClearForm] = useState(false)
  const [getRecentValues, setGetRecentValues] = useState(false)
  const [activeActivity, setActiveActivity] = useState(
    caseInput && caseInput?.activities?.length > 0 ? 0 : "overview"
  )

  const costs =
    inputData.activities.length > 0
      ? inputData.activities.at(0).costs.map((cost) => ({
          name: cost.name,
          variable: cost.variable,
          appliedFor: "0",
          approved: "0",
        }))
      : []

  const dynamicCostActivityDefault = {
    ...activityDefault,
    costs,
  }

  const [defaultValue, setDefaultValue] = useState(
    inputData?.defaultValue || dynamicCostActivityDefault || activityDefault
  )

  const agreementStore = useCreateAgreementStore(agreements)
  const [appliedAmountDeviation, setAppliedAmountDeviation] = useState(
    inputData?.appliedAmountDeviation ||
      flow.data.mapped.caseMemo?.mappedStateAid?.appliedAmountDeviation ||
      ""
  )
  const [activeTab, setActiveTab] = useState("evaluateBasis")

  //validation
  const [errors, setErrors] = useState([])
  const [showValidation, setShowValidation] = useState(false)
  const [productValidationError, setProductValidationError] = useState("")

  //submitting or saving
  const [isLoading, setLoading] = useState(false)

  const [caseComment, setCaseComment] = useState(
    task?.data?.caseComment || stateCaseComment || ""
  )

  // Can't use returnFirstArgWithValue here because empty arrays are falsy.
  const getInitialProjectParticipants = () => {
    // If data exists on task.data (it has been saved before), use this
    if (Array.isArray(task.data?.projectParticipants)) {
      return task.data?.projectParticipants
    }

    // If data exists on state already, use this
    if (context.projectParticipantsUpdated) {
      return context.projectParticipantsUpdated
    }

    // Otherwise, use the one from task context
    return context.projectParticipants
  }

  const activityExists = (productName) =>
    caseInput?.activities?.some(
      (activity) =>
        activity?.products?.some((product) => {
          const currentProduct = productConfiguration[product.product]
          return currentProduct?.[productName] ?? false
        }) ?? false
    ) ?? false

  const isTaxonomyRelevant = activityExists("showTaxonomy")
  const isInnovationAndDigitalizationRelevant = activityExists(
    "showInnovationAndDigitalization"
  )
  const isSomeProductDpv = activityExists("isDpv")
  const isSustainabilityRelevant = activityExists("showSustainability")

  // Used for DNI's Project Participants tab.
  const [participants, setParticipants] = useState(
    getInitialProjectParticipants()
  )

  const handleBlur = (data) => {
    if (activeActivity !== "overview") {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }

      if (data.isCustomActivity) {
        const activityDisplayData = caseInput[activeActivity]
        if (activityDisplayData) {
          activityDisplayData.staticTotal = activityDisplayData?.costs.reduce(
            (acc, curr) => acc + parseFloat(curr.appliedFor),
            0
          )
          activityDisplayData.activityTitle = data.activityTitle
        }
      }
      clone.activities[activeActivity] = data
      setCaseInput(clone)
      mappedInputDataSet(mapCaseInput(caseInput, productConfiguration))
    }
  }
  /**This function allows casehandler to edit activity name.
   * In the future it might be aaditional fields that IN wants to make it editable.
   * So I have created a more generic function name */
  const editCost = (index, name, value) => {
    if (activeActivity === "overview") {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }
      const defaultActivityClone = { ...defaultValue }

      clone.activities = clone.activities.map((activity) => ({
        ...activity,
        costs: activity.costs.map((cost, i) =>
          i === index ? { ...cost, [name]: value } : cost
        ),
      }))

      setDefaultValue({
        ...defaultActivityClone,
        costs: defaultActivityClone.costs.map((cost, i) =>
          i === index ? { ...cost, [name]: value } : cost
        ),
      })
      setCaseInput(clone)
    }
  }

  /**This function allows casehandler to add one for more cost to the existing costs */
  const addCosts = () => {
    if (activeActivity === "overview") {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }
      const defaultActivityClone = { ...defaultValue }
      const id = uuidv4()
      clone.activities.forEach((activity) => {
        activity.costs.push({
          name: null,
          variable: id,
          appliedFor: "0",
          approved: "0",
        })
      })

      defaultActivityClone.costs.push({
        name: null,
        variable: id,
        appliedFor: "0",
        approved: "0",
      })

      setDefaultValue(defaultActivityClone)
      setCaseInput(clone)
    }
  }

  /** This function allows casehandler to remove a cost in costs
   * After removal, we also explicitly recalculate the staticTotal in order to show
   * it correctly in the sidebar.
   */
  const removeCosts = (index) => {
    if (activeActivity === "overview") {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }
      const defaultActivityClone = { ...defaultValue }

      clone.activities.forEach((activity) => {
        activity.costs.splice(index, 1)

        activity.staticTotal = activity?.costs.reduce(
          (acc, curr) => acc + parseFloat(curr.appliedFor),
          0
        )
      })

      defaultActivityClone.costs.splice(index, 1)

      setCaseInput(clone)
    }
  }

  const resetCaseInput = () => {
    setCaseInput(caseInputClone)
  }

  const createClone = () => {
    const clone = lodash.cloneDeep(caseInput)
    setCaseInputClone(clone)
  }

  const hasEosArticles = Object.values(productConfiguration).some(
    ({ eosArticles }) => eosArticles?.length > 0
  )

  const isDynamicCostBased =
    flow.data.application?.applicationContent?.isDynamicCostBased

  const validateProductsAndSetErrors = () => {
    if (!shouldValidateProducts(hasEosArticles, isDynamicCostBased)) return true

    const invalidProducts = validateAndShowInvalidProducts(
      caseInput?.activities || [],
      productConfiguration
    )
    if (!invalidProducts || invalidProducts.length === 0) {
      setProductValidationError("")
      return true
    }
    if (invalidProducts?.length > 0) {
      const productValidationErrors = invalidProducts
        .reduce((errors, product) => {
          if (product?.displayName !== "noOption") {
            errors.push(product.displayName)
          }
          return errors
        }, [])
        .join(", ")
      const errorMessage = productValidationErrors
        ? `Følgende virkemiddel er ikke tillatt under valgt støtteregime: ${productValidationErrors}`
        : ""
      setProductValidationError(errorMessage)
      return !errorMessage
    }
    return true
  }

  useEffect(() => {
    validateProductsAndSetErrors()
  }, [caseInput, hasEosArticles, productConfiguration])

  const onSave = (e) => {
    setLoading(true)
    const payload = {
      ...caseInput,
      defaultValue,
      appliedAmountDeviation,
      caseComment,
      agreements: agreementStore.getAll(),
      // Spread in projectParticipants if DNI
      ...(isDni ? { projectParticipants: participants } : {}),
    }
    save(
      payload,
      () => {
        setLoading(false)
      },
      () => {
        console.error("Could not complete task")
        setLoading(false)
      }
    )
  }
  const costOverview = mapCostOverview(caseInput.activities)
  const onComplete = (e) => {
    mappedInputDataSet(mapCaseInput(caseInput, productConfiguration))
    const { errors } = validate(
      caseInput,
      false,
      productConfiguration,
      t,
      agreementStore.getAll(),
      false,
      isDni ? participants ?? [] : false
    )
    setShowValidation(true)
    setErrors(errors)

    const isProductCombinationValid = validateProductsAndSetErrors()

    if (errors?.length === 0 && isProductCombinationValid) {
      const data = {
        ...caseInput,
        isTaxonomyRelevant,
        isInnovationAndDigitalizationRelevant,
        isSustainabilityRelevant,
        isSomeProductDpv,
        agreements: agreementStore.getAll(),
        ...(isDni ? { projectParticipants: participants } : {}),
        summaries: {
          appliedAmountDeviation: appliedAmountDeviation,
          endDate: mappedInputData?.endDate,
          startDate: mappedInputData?.startDate,
          totalApprovedCosts,
          totalOriginalCosts: totalAppliedForCosts,
          costOverviewSummary: costOverview,
          amountSummary: {
            loanTotalHighRisk: {
              totalAppliedFor: null,
              totalGranted: totalProposed?.loanTotalHighRisk,
            },
            loanTotalLowRisk: {
              totalAppliedFor: null,
              totalGranted: totalProposed?.loanTotalLowRisk,
            },
            grant: {
              totalAppliedFor: totalAppliedFor?.grant,
              totalGranted: totalProposed?.grant,
            },
            loan: {
              totalAppliedFor: totalAppliedFor?.loan,
              totalGranted: totalProposed?.loan,
            },
            guarantee: {
              totalAppliedFor: totalAppliedFor?.guarantee,
              totalGranted: totalProposed?.guarantee,
            },
          },
        },
      }
      setLoading(true)
      complete(
        data,
        () => {
          setLoading(false)
        },
        () => {
          console.error("Could not complete task")
          setLoading(false)
        }
      )
    }
  }

  const handleChangeActivity = async (selectedIndex) => {
    if (selectedIndex !== index) {
      setGetRecentValues(true)
      setIndex(selectedIndex)
    }
  }

  useEffect(() => {
    if (getRecentValues) {
      setActiveActivity(index)
      setClearForm(true)
    }
  }, [getRecentValues])

  useEffect(() => {
    mappedInputDataSet(mapCaseInput(caseInput, productConfiguration))
  }, [caseInput])

  useEffect(() => {
    const { errors } = validate(
      caseInput,
      false,
      productConfiguration,
      t,
      agreementStore.getAll(),
      false,
      isDni ? participants ?? [] : false
    )
    setErrors(errors)
  }, [mappedInputData, caseInput])

  const simpleChangeActivity = (index) => {
    setIndex(index)
    setActiveActivity(index)
    setClearForm(true)
  }

  const addActivity = useCallback(
    /**
     * @param {Activity} activity
     */
    (activity) => {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }
      const activityData = {
        ...defaultValue,
      }
      activityData.isCustomActivity = true
      clone.activities.push(activityData)
      setCaseInput(clone)
      mappedInputDataSet(mapCaseInput(caseInput, productConfiguration))
      // Cannot use handleChangeActivity due to it fetching recent values, overwriting the product with empty values
      simpleChangeActivity(clone.activities.length - 1)
    },
    [caseInput, defaultValue]
  )

  const removeActivity = useCallback(
    (index) => {
      const clone = { ...caseInput }
      if (!clone?.activities) {
        clone.activities = []
      }

      clone.activities.splice(index, 1)
      //caseInput.splice(index, 1)

      setCaseInput(clone)
      mappedInputDataSet(mapCaseInput(caseInput, productConfiguration))
      if (activeActivity >= index) simpleChangeActivity(activeActivity - 1)
      else simpleChangeActivity(activeActivity)
    },
    [caseInput]
  )

  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const options = [
    {
      id: "evaluateBasis",
      title: t("set-support-basis"),
      component: (
        <EvaluateBasisForSupportComponent
          t={t}
          flow={flow}
          data={{
            activeActivity,
            caseInput,
            totalOriginalCosts: totalAppliedForCosts,
            totalAppliedFor,
            totalProposed,
            mappedInputData,
            currency,
            category,
            isForeignAccount,
          }}
          options={{
            isDpv,
            productConfiguration,
            isSingleActivity,
          }}
          functions={{
            handleChangeActivity,
            handleBlur,
            clearForm,
            setClearForm,
            getRecentValues,
            setGetRecentValues,
            addActivity,
            removeActivity,
            editCost,
            addCosts,
            removeCosts,
            resetCaseInput,
            createClone,
          }}
        />
      ),
    },
    ...(isDni
      ? [
          {
            id: "projectParticipants",
            title: t("project-participants"),
            component: (
              <ProjectParticipants
                t={t}
                participants={participants}
                setParticipants={setParticipants}
                insightComponentData={insightComponentData}
              />
            ),
          },
        ]
      : []),
    ...(showAvailableFramesTab
      ? [
          {
            id: "availableFrames",
            title: t("available-frames"),
            component: (
              <AvailableFrames
                availableFrames={availableFrames}
                t={t}
                onTrigger={onTrigger}
                flowId={flow.flowId}
                updateCase={updateCase}
              />
            ),
          },
        ]
      : []),
    {
      id: "comments",
      title: t("requested-documentation"),
      component: (
        <RequestedDocArchive
          documentations={previouslyRequestedDocuments}
          t={t}
        />
      ),
    },

    {
      id: "decisions",
      title: t("assessments"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentComments
          comments={flow?.data?.assessmentComments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]

  const tab = options.find((x) => x.id === activeTab)
  return (
    <Layout forceHeight>
      <DisplayContent>
        <Content>
          <AgreementProvider store={agreementStore}>
            <TabWrapper>
              <Tabs options={options} onChange={(e) => setActiveTab(e)} />
            </TabWrapper>
            <TabContainer>{tab.component}</TabContainer>
          </AgreementProvider>
        </Content>
        <Context context={applicationSummary} flow={flow}>
          <TextArea
            style={{ marginBottom: "15px" }}
            size="large"
            label={t("justify-deviations-from-applied-amount")}
            name="myInput"
            value={appliedAmountDeviation}
            onChange={(e) => setAppliedAmountDeviation(e.target.value)}
          />
          {showValidation &&
            errors?.map((e, i) => {
              return <ErrorContext key={`b${i}`} t={t} error={e} />
            })}
          {productValidationError && (
            <ErrorContext
              key={`b${productValidationError}`}
              t={t}
              error={productValidationError}
            />
          )}
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              disabled={isLoading}
              onClick={() => onComplete()}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isLoading}
              onClick={() => onSave()}
            >
              {t("save-and-close")}
            </SecondaryButton>
          </ButtonContainer>
        </Context>
      </DisplayContent>
    </Layout>
  )
}

export default EvaluateBasisForSupportCommon

const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`
const TabWrapper = styled.div`
  margin: 0px 40px;
  margin-top: 10px;
`

const Content = styled.div`
  height: 100%;
  width: 150%;
  overflow-y: auto;
  border-right: 1px solid #e4e2e2;
`

const DisplayContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: none;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
