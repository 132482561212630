import styled from "styled-components"
import { Colors } from "@flow/style"
import React, { useEffect, useState } from "react"
import { Select, TextArea } from "@flow/forms"
import { TransparentButton, SecondaryButton } from "@flow/buttons"
import { Icons } from "@flow/icons"
import axios from "axios"
import IconButton from "../common/IconButton"

const getSpecialTermTags = async (category, setError) => {
  let listOfTerms = []
  try {
    const parameter = encodeURIComponent(`${category.label}`)
    const res = await axios.get(`/api/special-terms?tag=${parameter}`)
    const terms = res.data.map((term) => {
      return {
        label: term.title,
        value: term.title.split(" ")[0],
        userInstructions: term.userInstructions,
        termText: term.terms,
      }
    })
    listOfTerms = terms
  } catch (error) {
    setError(true)
    console.error("An error occured while fetching terms for a given category")
  }
  return listOfTerms
}

/**
 * Component containing a special term in a delivery. The special term consists
 * of a category, a term, user instructions if any and the text of the term.
 * @param {object} props The properties of the SpecialTerm component
 * @param {InFlow.SpecialTerm} props.specialTerm Contains information about the term and variables for
 * determining if it is in edit (disabled) or can be deleted.
 * @param {function} props.onDelete Function triggered when deleting a special term
 * @param {function} props.onAdd Function triggered when adding a special term in edit
 * @param {function} props.onCancel Function trigger when cancling the process of adding a new special term
 * @param {function} props.t Translation
 * @param {boolean} props.disabled If the special term is disabled
 * @param {boolean} props.deletable If the special term is deletable
 * @param {Array} [props.categories] The categories of the special terms
 * @param {InFlow.DeliveryItems} props.deliveries The deliveries in the application
 * @param {string[]} [props.deliveryIdsToAdd] The delivery ids to add the special term to
 * @param {function} [props.setDeliveryIdsToAdd] The function to set the delivery ids to add the special term to
 * @param {string} props.currentDeliveryId The current delivery id
 * @returns {JSX.Element}
 */
const SpecialTerm = ({
  specialTerm,
  onDelete,
  onAdd,
  onCancel,
  t,
  disabled,
  deletable,
  categories = [],
  deliveries = {},
  deliveryIdsToAdd,
  setDeliveryIdsToAdd,
  currentDeliveryId,
}) => {
  const Icon = Icons.Help
  const [listOfTerms, setListOfTerms] = useState(specialTerm.terms || [])
  const [currentTerm, setCurrentTerm] = useState(specialTerm)
  const [checkSpecialTermsError, setCheckSpecialTermsError] = useState(false)

  useEffect(() => {
    setCurrentTerm(specialTerm)
  }, [specialTerm])

  const onChangedCategory = async (e, value) => {
    // Start of by clearing the existing data to prevent confusion when waiting for the new
    setListOfTerms([])
    const category = categories.find((category) => {
      return category.value === value
    })
    setCurrentTerm({
      ...currentTerm,
      categoryLabel: category.label,
      category: value,
      term: null,
      userInstructions: null,
      termText: "",
    })
    const listOfTerms = await getSpecialTermTags(
      category,
      setCheckSpecialTermsError
    )
    setListOfTerms(listOfTerms)
  }
  const onChangedTerm = (e, value) => {
    const index = listOfTerms.findIndex((existing) => existing.value === value)
    setCurrentTerm({
      ...currentTerm,
      term: value,
      termLabel: listOfTerms[index].label,
      userInstructions: listOfTerms[index].userInstructions,
      termText: listOfTerms[index].termText,
    })
  }

  const onChangeText = (e) => {
    setCurrentTerm({
      ...currentTerm,
      termText: e.target.value,
    })

    e.target.style.height = `${e.target.scrollHeight}px`
  }

  const onAddSpecialTerm = () => {
    const { termText, disabled, categoryLabel, userInstructions, termLabel } =
      currentTerm
    onAdd({
      termText,
      disabled,
      userInstructions,
      term: termLabel,
      category: categoryLabel,
    })
    setDeliveryIdsToAdd([])
  }

  const handleSelectDelivery = (deliveryId, selectedDeliveryIds) => {
    // If deliveryId is already selected, remove it from the list
    if (deliveryIdsToAdd.includes(deliveryId)) {
      setDeliveryIdsToAdd(selectedDeliveryIds.filter((id) => id !== deliveryId))
    } else {
      setDeliveryIdsToAdd([...selectedDeliveryIds, deliveryId])
    }
  }

  const hasMultipleDeliveries = Object.keys(deliveries || {})?.length > 1

  return (
    <>
      <Divider />
      <FlexRow>
        {(!disabled && (
          <>
            <StyledSelect>
              <Select
                id="category"
                label={t("category")}
                onChange={onChangedCategory}
                options={categories || []}
                disabled={disabled}
                value={currentTerm?.category}
                showBlankOption={!disabled}
                width={"100%"}
              />
            </StyledSelect>
            <Divider />
            <StyledSelect>
              <Select
                id="term"
                label={t("term")}
                onChange={onChangedTerm}
                options={listOfTerms || []}
                disabled={disabled}
                value={currentTerm?.term}
                showBlankOption={!disabled}
                width={"100%"}
                min-width={"355px"}
              />
            </StyledSelect>
          </>
        )) || (
          <SpecialFlexRow>
            <>
              <GreyText>{t("CATEGORY")} </GreyText>
              <Bold>{currentTerm?.category}</Bold>
            </>
            <Divider />
            <>
              <GreyText>{t("TERM")} </GreyText> <Bold>{currentTerm?.term}</Bold>
            </>
            <Divider />
            {deletable && (
              <div>
                <IconButton
                  onClick={() => onDelete(specialTerm)}
                  icon={"Trashcan"}
                />
              </div>
            )}
          </SpecialFlexRow>
        )}
      </FlexRow>
      {currentTerm?.userInstructions && (
        <>
          <Divider />
          <FlexRow>
            <div>
              <Icon color={Colors.Grey1} size={20} />
            </div>
            <UserInstructions>{currentTerm?.userInstructions}</UserInstructions>
          </FlexRow>
        </>
      )}
      <Divider />
      {checkSpecialTermsError ? (
        <LoadingPage>
          <Icons.ErrorCircle />
          <div>
            {t("could-not-fetch-terms")} <br /> {t("close-and-try-again")}
          </div>
        </LoadingPage>
      ) : (
        <>
          <StyledTextArea
            name={"termTextArea"}
            onChange={onChangeText}
            value={currentTerm?.termText}
            disabled={disabled}
            placeholder={t("term-text-placeholder")}
          />
          {!disabled && (
            <>
              <StyledCheckBoxWrapper>
                {hasMultipleDeliveries && (
                  <Bold>{t("add-special-terms-multiple")}</Bold>
                )}
                {hasMultipleDeliveries &&
                  Object.keys(deliveries)
                    .filter((deliveryId) => deliveryId !== currentDeliveryId)
                    .map((deliveryId, i) => (
                      <>
                        <StyledCheckBox>
                          <input
                            onChange={(val) =>
                              handleSelectDelivery(deliveryId, deliveryIdsToAdd)
                            }
                            key={deliveryId}
                            type={"checkbox"}
                            checked={deliveryIdsToAdd?.includes(deliveryId)}
                            disabled={disabled}
                          />
                          <label>{deliveries[deliveryId]?.productName}</label>
                        </StyledCheckBox>
                      </>
                    ))}
              </StyledCheckBoxWrapper>
              <Divider />
              <FlexRow>
                <SecondaryButton
                  onClick={() => onAddSpecialTerm()}
                  disabled={!currentTerm.termText}
                >
                  {t("add-special-terms-button-text")}
                </SecondaryButton>
                <TransparentButton onClick={() => onCancel()}>
                  {t("cancel-special-terms-button-text")}
                </TransparentButton>
              </FlexRow>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SpecialTerm

const UserInstructions = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Grey1};
  font-size: 13px;
`
const Bold = styled.div`
  font-weight: 500;
  white-space: nowrap;
`
const GreyText = styled.div`
  color: ${Colors.Grey1};
`
const StyledSelect = styled.div`
  min-width: 355px;
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`
// Used for adding space between the trashcan and the categories and terms.
const SpecialFlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;

  div:last-of-type {
    margin-left: auto;
  }
`
const Divider = styled.div`
  margin: 15px;
`
const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
`
const LoadingPage = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-left: 10px;
  }
`
const StyledCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  label {
    padding-left: 0.5em;
  }
  margin-top: 0.5em;
`
const StyledCheckBoxWrapper = styled.div`
  margin-top: 0.5em;
  padding: 0.8em 0em;
`
