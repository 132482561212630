import _ from "lodash"
import {
  collateralAgreementTypes,
  collateralObjectTypes,
} from "../../util/collateralTypes"
import { getEngagementAmount } from "../../util/engagementAmount"
import { categoriesConstants } from "../../util/getInsightContextForCategory"
import { formatDate } from "./formatDate"

const NO_SAFETY_RISK = "Ikke tilgjengelig"

/**
 *
 * @param {Deliveries} deliveries
 * @returns {object} The delivery by agreement ID.
 */
export const deliveryByAgreementId = (deliveries) =>
  Object.keys(deliveries).reduce((deliveryAgreements, deliveryId) => {
    const agreementId = deliveries[deliveryId].agreement.data.internalId
    deliveryAgreements[agreementId] = deliveryId
    return deliveryAgreements
  }, {})

/**
 * Maps agreement data to a new structure.
 * @param {AgreementsDataState} agreementsDataState - The current state of the agreements data.
 * @param {object} flow - The current flow of the application.
 * @param {Array} guarantors - The list of guarantors.
 * @param {object} securityRiskData - The security risk data.
 * @param {boolean} useNewMapping - Indicates whether to use the new mapping.
 * @returns {MappedCollateralAgreement[]} The mapped agreement data.
 */
export const mapAgreementData = (
  agreementsDataState,
  flow,
  guarantors,
  securityRiskData,
  useNewMapping = false
) => {
  const agreementData = agreementsDataState || {
    agreementData: [],
    changesData: [],
    collateral: {
      collateralAgreements: [],
      collateralObjects: [],
    },
  }

  /**
   * @type {Insight.CollateralAgreement[]} The collateral agreements.
   */
  const collateralAgreements =
    agreementData?.collateral?.collateralAgreements || []

  const collateralObjects = agreementData?.collateral?.collateralObjects || []
  const changedAgreements = agreementData?.changesData || []

  const loans =
    agreementsDataState?.agreementData?.filter(
      (agreement) => agreement.type === "LOAN"
    ) || []

  /**
   * @typedef {object} CoDebtor - The co-debtor.
   * @property {string} publicId - The public ID.
   * @property {string} name - The name.
   *
   * @param {AgreementsDataState} agreementData - The insight agreement object.
   * @param {string} internalId - The internal ID.
   * @param {object} guarantors - The list of guarantors.
   * @returns {CoDebtor[]} The co-debtors.
   *
   */
  const getCoDebtor = (agreementData, internalId, guarantors) => {
    const accountRoles = agreementData?.agreementData?.reduce(
      (acc, agreement) => {
        if (agreement.data.internalId === internalId) {
          return agreement.data.basicAccountInformation.accountRoles
        }
        return acc
      },
      []
    )
    if (accountRoles?.length !== 0) {
      const coDebtorId = (accountRoles || [])
        .filter((x) => x.role === "CODEBTOR")
        .map((x) => x.id)

      const coDebtorsNamesInACoolList = guarantors
        ?.filter((x) => coDebtorId?.includes(x?.ssn || x?.organizationNumber))
        .map((x) => ({
          publicId: x?.ssn || x?.organizationNumber,
          name: x?.name,
        }))

      return coDebtorsNamesInACoolList
    }
    return null
  }

  /**
   * Creates a cadastre string for a given cadastre object.
   * @param {Object} data - The data object.
   * @param {Insight.Cadastre} data.cadastre - The cadastre object.
   * @param {string} data.movables - The movables.
   *
   * @returns {string} The cadastre string.
   */
  const createDatCatestrass = ({ cadastre, movables }) => {
    const {
      cadastralUnitNumber,
      municipalityNumber,
      sectionNumber,
      // subLeaseHoldUnitNumber, returns null
      leaseHoldUnitNumber,
      unitNumber,
    } = cadastre || {}
    if (movables === "REAL_ESTATE") {
      return `${municipalityNumber || 0}-${cadastralUnitNumber || 0}/${
        unitNumber || 0
      }/${leaseHoldUnitNumber || 0}/${sectionNumber || 0}`
    } else {
      return movables
    }
  }
  const filterOutTheMainCadastrass = (
    collateralAssosciatedPropertiesBeforeMap,
    collateralObjectCadastre
  ) => {
    return (
      collateralAssosciatedPropertiesBeforeMap?.filter(
        (x) => x !== collateralObjectCadastre
      ) || []
    )
  }
  const checkWhoItBe = (idlist, guarantor) => {
    return guarantor?.filter((x) => idlist?.includes(x?.id)).map((x) => x?.name)
  }

  /**
   * Gets the deliveries for a given list of engagement IDs.
   * @param {string[]} engagementIds - The list of engagement IDs.
   * @param {CollateralAgreementData} currentAgreement - The current agreement.
   * @param {object[]} changedAgreements - the list of changed agreement
   * @returns {DeliveryProduct[]} The deliveries.
   */
  const getDeliveries = (
    engagementIds,
    currentAgreement,
    changedAgreements
  ) => {
    //engamentsIds === internal på alle engasjementene
    return Object.keys(flow?.data?.deliveries)?.reduce((acc, deliveryId) => {
      const currentDelivery = flow?.data?.deliveries[deliveryId]
      const engagement = currentDelivery?.agreement?.data

      /**
       * @type {Insight.CollateralEngagement[]}
       */
      const mappedCollateralEngagements =
        flow?.data?.mapped?.collateralEngagements || []
      /**
       * @type {CoreView.Engagement[]}
       */
      const engagements = flow?.data?.mapped?.engagement?.loans || []
      /**
       * Gets the collateral coverages for a given agreement.
       * @type {CollateralCoverage[]} The collateral coverages. updated
       */

      const changedCollateral = changedAgreements.find(
        (agreement) =>
          agreement.data.basicChangeInformation.parentId ===
          currentAgreement.internalId
      )

      const collateralCoverages =
        currentAgreement.basicCollateralAgreementInformation?.collateralCoverages
          .map((coverage) => {
            //Kjøres kun dersom endringen er av type COLLATERALCOVERAGES
            if (
              changedCollateral &&
              changedCollateral.type === "COLLATERALCOVERAGES"
            ) {
              const filteredCollateral =
                changedCollateral?.data?.collateralCoverages?.filter(
                  (item) => item.changeStatus !== "DELETED"
                )
              const coverageFound = filteredCollateral.find(
                (item) => item.internalId === coverage.internalId
              )
              if (coverageFound) return coverage
            } else {
              return coverage
            }
          })
          .filter((item) => !!item)

      // Finner lån som denne avtalen står som sikkerhet for
      const collateralCoveragesOnCurrentAgreement =
        collateralCoverages
          ?.map(({ internalId }) => {
            return (
              mappedCollateralEngagements.length &&
              mappedCollateralEngagements?.find(
                (engagement) =>
                  engagement?.data?.internalId === internalId &&
                  engagement?.data?.basicAccountInformation.version !== "NEW"
              )
            )
          })
          ?.filter((item) => !!item) ?? []

      const agreements = engagementIds?.find(
        (id) => id === engagement?.internalId
      )

      if (agreements) {
        const safetyRisk =
          securityRiskData?.find((x) => x?.deliveryId === deliveryId)
            ?.safetyRisk || "Ikke valgt"
        acc.push({
          productName: flow?.data?.deliveries[deliveryId]?.productName,
          productAmount:
            flow?.data?.deliveries[deliveryId]?.agreement?.data
              ?.specificAccountTypeInfo?.initialLoanInformation?.loanAmount ||
            0,
          safetyRisk,
          coDebitor: getCoDebtor(
            agreementData,
            engagement?.internalId,
            guarantors
          ),
          deliveryId,
        })
      }

      if (collateralCoveragesOnCurrentAgreement.length > 0) {
        collateralCoveragesOnCurrentAgreement.forEach((x) => {
          const safetyRisk = getSafetyRisk(
            x?.data?.basicAccountInformation?.accountNumber,
            engagements
          )
          const accountNumber =
            x?.data?.basicAccountInformation?.accountNumber || "" // accountNumber is not always set, so set it to an empty string to use for comparison later

          const exist = acc.findIndex((x) => x.accountNumber === accountNumber)
          if (exist === -1) {
            acc.push({
              productName: x?.data?.basicAccountInformation?.name,
              productAmount:
                x?.data?.specificAccountTypeInfo?.initialLoanInformation
                  ?.loanAmount || 0,
              safetyRisk,
              version: x?.data?.basicAccountInformation?.version,
              // accountRoles returns bs atm, probably needs fix in agreementsummary app when there is proper mockdata
              // coDebitor:x?.data?.basicAccountInformation?.accountRoles,
              deliveryId,
              accountNumber,
            })
          }
        })
      }
      return acc
    }, [])
  }

  /**
   * Gets the collateral object priority for a given agreement and collateral object.
   * @param {Insight.CollateralAgreement} agreement - The insight agreement object.
   * @param {CollateralObject} collateralObject - The insight collateral object.
   * @returns {number[]} The collateral object priority.
   */
  const getCollateralObjectPriority = (agreement, collateralObject) => {
    // Match the agreement to the collateralObject liens
    const collateralObjectLiens =
      collateralObject?.basicCollateralObjectInformation?.collateralObjectLiens

    const collateralObjectLiensFiltered = collateralObjectLiens?.filter(
      (lien) => lien.agreementId === agreement.internalId
    )
    return collateralObjectLiensFiltered?.map((y) => y?.priority)
  }

  /**
   * Gets the collateral object value for a given collateral object.
   * @param {CollateralObject} collateralObject - The insight collateral object.
   * @returns {number} The collateral object value.
   */
  const getCollateralObjectsValue = (collateralObject) => {
    if (collateralObject?.basicCollateralObjectInformation?.type === "SHARES") {
      return collateralObject?.collateralObjectDetails.faceValue ?? 0
    }
    return (
      collateralObject?.basicCollateralObjectInformation?.collateralValuation
        ?.marketValuation?.amount ?? 0
    )
  }

  const formatCollateralDate = (date) => {
    if (date) {
      return formatDate(date)
    }
    return "Ikke satt"
  }

  /**
   * @param {Insight.CollateralObject} collateralObject - The product code.
   */
  const generateCustomColumns = (collateralObject) => {
    switch (collateralObject?.basicCollateralObjectInformation?.type) {
      case collateralObjectTypes.ship:
        return [
          {
            header: "NAVN",
            value: collateralObject?.collateralObjectDetails?.shipName,
          },
          {
            header: "CALL SIGN",
            value: collateralObject?.collateralObjectDetails?.callSign,
          },
          {
            header: "TYPE",
            value: collateralObject?.collateralObjectDetails?.shipType,
          },
        ]
      case collateralObjectTypes.aircraft:
        return [
          {
            header: "NAVN / TYPE",
            value: collateralObject?.collateralObjectDetails?.name,
          },
          {
            header: "CALL SIGN",
            value: collateralObject?.collateralObjectDetails?.callSign,
          },
        ]
      default:
        return []
    }
  }

  /**
   * Gets the collateral objects for a given agreement.
   * @param {Insight.CollateralAgreement} agreement - The insight agreement object.
   * @param {Insight.CollateralObject[]} collateralObjects - The insight collateral objects.
   * @returns {MappedCollateralObject[]} The collateral objects.
   */
  const getCollateralObjects = (agreement, collateralObjects) => {
    const objects = collateralObjects.filter((x) =>
      agreement?.collateralObjectsReferences?.includes(x.internalId)
    )
    return objects.map((x) => ({
      collateralObjectsValue: getCollateralObjectsValue(x),
      collateralObjectCopyRightHolder: checkWhoItBe(
        x?.basicCollateralObjectInformation?.owners?.map((y) => y?.ownerId),
        guarantors
      ),
      collateralObjectPriority: getCollateralObjectPriority(agreement, x),
      carStuff:
        x?.basicCollateralObjectInformation?.type === "VEHICLE"
          ? {
              type: x?.collateralObjectDetails?.vehicleType,
              subType: x?.collateralObjectDetails?.vehicleSubType,
              registrationNumber:
                x?.collateralObjectDetails?.registrationData
                  ?.registrationNumber,
              vehicleIdentificationNumber:
                x?.collateralObjectDetails?.registrationData
                  ?.vehicleIdentificationNumber,
              vehicleBrand:
                x?.collateralObjectDetails?.registrationData?.vehicleBrand,
              vehicleModel:
                x?.collateralObjectDetails?.registrationData?.vehicleModel,
              dateFirstRegistered: formatDate(
                x?.collateralObjectDetails?.registrationData
                  ?.dateFirstRegistered
              ),
            }
          : {},
      customColumns: generateCustomColumns(x),
      description:
        x?.basicCollateralObjectInformation?.basicCollateralInformation
          ?.description === "Beskrivelse"
          ? ""
          : x?.basicCollateralObjectInformation?.basicCollateralInformation
              ?.description,

      marketValueDate: formatCollateralDate(
        x?.basicCollateralObjectInformation?.collateralValuation
          ?.marketValuation?.marketValueDate
      ),
      marketingName:
        x?.basicCollateralObjectInformation?.basicCollateralInformation
          ?.productInformation?.marketingName,
      collateralObjectCadastre: createDatCatestrass({
        cadastre: x?.collateralObjectDetails?.cadastre,
        movables:
          x?.basicCollateralObjectInformation.collateralObjectType ||
          x?.basicCollateralObjectInformation.type,
      }),
      collateralAssosciatedProperties: filterOutTheMainCadastrass(
        x?.collateralObjectDetails?.associatedProperties?.map((y) =>
          createDatCatestrass({
            cadastre: y?.cadastre,
            movables:
              x?.basicCollateralObjectInformation.collateralObjectType ||
              x?.basicCollateralObjectInformation.type,
          })
        ) || [],
        createDatCatestrass({
          cadastre: x?.collateralObjectDetails?.cadastre,
          movables:
            x?.basicCollateralObjectInformation.collateralObjectType ||
            x?.basicCollateralObjectInformation.type,
        })
      ),
    }))
  }

  /**
   * Gets the collateral amount for a given agreement.
   * @param {Insight.CollateralAgreement} agreement - The insight agreement object.
   * @param {ChangesData[]} changedAgreements - The list of changed agreements.
   * @returns {number} The collateral amount.
   */
  const getCollateralAmount = (agreement, changedAgreements) => {
    // Check if changed agreement has a new collateral amount
    const changedAgreement = changedAgreements?.find(
      (x) => x?.data?.basicChangeInformation?.parentId === agreement?.internalId
    )

    if (
      changedAgreement &&
      changedAgreement?.data?.reducedNominalAmount?.amount > 0
    ) {
      return changedAgreement?.data?.reducedNominalAmount?.amount
    } else {
      return (
        agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
          ?.mortgageDeedFaceValue ||
        agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
          ?.faceValue ||
        agreement?.specificCollateralAgreementInformation?.suretyAmount ||
        agreement?.specificCollateralAgreementInformation
          ?.otherCollateralAgreementDetails?.faceValue ||
        0
      )
    }
  }

  /**
   * Gets the agreement type for a given agreement.
   * @param {Insight.CollateralAgreement} agreement - The insight agreement object.
   * @returns {"OTHER" | "MOVABLES" | "REAL_ESTATE"} The collateral amount.
   */
  const getAgreementType = (agreement) => {
    const agreementType =
      agreement?.specificCollateralAgreementInformation?.mortgageDeedType ||
      agreement?.specificCollateralAgreementInformation?.suretyType
    return agreementType || "OTHER"
  }

  const mappedCollateralAgreements = collateralAgreements.map((agreement) => {
    return {
      agreementId: agreement.internalId,
      agreementType: getAgreementType(agreement),
      collateralAmount: getCollateralAmount(agreement, changedAgreements),
      collateralObject: getCollateralObjects(agreement, collateralObjects),
      collateralObjectSecurity: getDeliveries(
        agreement?.basicCollateralAgreementInformation?.collateralCoverages?.map(
          (x) => x?.internalId
        ),
        agreement,
        changedAgreements
      ),
      expiryDate: formatDate(
        agreement?.basicCollateralAgreementInformation?.expiryDate
      ),
      maximumAmount:
        agreement?.basicCollateralAgreementInformation?.collateralCoverages?.reduce(
          (acc, coverage) => acc + coverage?.collateralCoverage?.maximumAmount,
          0
        ) || 0,
      suretyType:
        agreement?.specificCollateralAgreementInformation?.suretyLiabilityType,
      mortgagors: checkWhoItBe(
        agreement?.basicCollateralAgreementInformation?.mortgagors?.map(
          (y) => y?.internalId
        ),
        guarantors
      ),
      version: getAgreementVersion(agreement, changedAgreements),
      productCode: `Pant i ${getDetailTypeNameByProductCode(agreement)}`,
    }
  })

  // Merge newEngagements with collateralEngagements
  if (useNewMapping) {
    const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
      mappedCollateralAgreements,
      loans,
      flow.data.mapped.engagement.loans || [],
      securityRiskData
    )
    return mappedLoansWithCollateralAgreements
  }
  return mappedCollateralAgreements
}
/**
 *
 * @param {string} accountNumber
 * @param {CoreView.Engagement[]} engagements - The engagements from coreview.
 * @returns {string} The safety risk.
 */
const getSafetyRisk = (accountNumber, engagements) => {
  const account = engagements?.find((x) => x?.accountNumber === accountNumber)
  const safetyRisk = account?.riskGroupCode

  const safetyRiskReturned = safetyRisk ? safetyRisk : NO_SAFETY_RISK
  return safetyRiskReturned
}

/**
 * @param {MappedCollateralAgreement[]} mappedAgreementData - The mapped agreement data.
 * @returns {MappedCollateralObjectSecurity[]} The unique collateral object securities.
 */
const getUniqueCollateralObjectSecurities = (mappedAgreementData) => {
  const collateralObjectSecurities =
    mappedAgreementData
      ?.map((agreement) => agreement?.collateralObjectSecurity)
      .flat() || []
  return [
    ...new Map(
      collateralObjectSecurities.map((item) => [item.accountNumber, item])
    ).values(),
  ]
}
/**
 * @param {MappedCollateralAgreement[]} mappedAgreementData - The mapped agreement data.
 * @param {string} accountNumber - The account number.
 * @returns {MappedCollateralAgreement[]} The mapped agreement data without account number.
 */
const getAgreementDataForAccountNumber = (
  mappedAgreementData,
  accountNumber
) => {
  return (
    mappedAgreementData.filter((agreement) =>
      agreement.collateralObjectSecurity?.some(
        (security) =>
          security.accountNumber === accountNumber ||
          (security.accountNumber === "" && accountNumber === "")
      )
    ) || []
  )
}

/**
 * @param {MappedCollateralAgreement[]} mappedAgreementData - The mapped agreement data.
 * @returns {MappedCollateralAgreement[]} The mapped agreement data without account number.
 */
const getAgreementDataWithoutAccountNumber = (mappedAgreementData) => {
  return mappedAgreementData.filter((agreement) =>
    agreement.collateralObjectSecurity?.some(
      (security) => security.accountNumber === "" || !security.accountNumber
    )
  )
}

/**
 * @param {CoreView.Engagement} coreViewEngagement - The coreview engagement.
 * @param {number} insightEngagementAmount - The insight engagement amount.
 * @param {string} version - The version of the engagement.
 * @returns {number} The loan amount.
 */
const getLoanAmount = (
  coreViewEngagement,
  insightEngagementAmount,
  version
) => {
  if (!coreViewEngagement || version === "NEW") {
    return insightEngagementAmount
  }
  return getEngagementAmount(coreViewEngagement)
}

// This function is the same as the one in generate-decision-basis, remember to update both if changes are made
/**
 * Maps agreement data to a new structure.
 * @param {MappedCollateralAgreement[]} mappedAgreementData - The mapped agreement data.
 * @param {CollateralEngagement[]} engagements - The engagements.
 * @param {CoreView.Engagement[]} coreviewEngagements - The engagements from coreview.
 * @param {object} securityRiskData - The security risk data.
 * @returns {DeliveryProductWithAgreementData[]} The mapped agreement data with deliveries.
 */
export const mapLoansWithAgreementData = (
  mappedAgreementData,
  engagements,
  coreviewEngagements,
  securityRiskData
) => {
  const defaultValues = {
    safetyRisk: NO_SAFETY_RISK,
    version: "NEW",
    deliveryId: "noDelivery",
    productAmount: 0,
    productName: "Ikke satt",
  }
  if (!mappedAgreementData) {
    return []
  }
  const uniqueCollateralObjectSecurities =
    getUniqueCollateralObjectSecurities(mappedAgreementData)
  const deliveries =
    engagements?.map((x) => {
      const accountNumber = x?.data?.basicAccountInformation?.accountNumber
      let agreementData = getAgreementDataForAccountNumber(
        mappedAgreementData,
        accountNumber
      )
      const loanVersion = x?.data?.basicAccountInformation?.version || ""
      const agreementDataWithoutAccountNumber =
        getAgreementDataWithoutAccountNumber(mappedAgreementData)

      // We need to make sure that we add the agreements without account number to the deliveries,
      // but we may have more than one new loan (delivery) without account number,
      // so we need to make sure we then also check for another property that is unique for the delivery
      if (
        !accountNumber ||
        (accountNumber === "" && agreementDataWithoutAccountNumber.length > 0)
      ) {
        // Check agreementData.collateralObjectSecurity.deliveryId === x.data.basicAccountInformation.productInformation.productId
        const agreementsWithSameProductId =
          agreementDataWithoutAccountNumber.filter((ag) =>
            ag.collateralObjectSecurity
              .map((x) => ({
                ...x,
                accountNumber: x?.accountNumber || "", // Need to set accountNumber to an empty string to be able to compare it with an empty string coming from insight data
              }))
              .some(
                (security) =>
                  security.deliveryId ===
                    x.data.basicAccountInformation.productInformation
                      .productId && security.accountNumber === accountNumber
              )
          )
        agreementData = agreementData.concat(agreementsWithSameProductId || [])
      }
      const relatedSecurities =
        uniqueCollateralObjectSecurities.filter(
          (y) =>
            (y?.accountNumber ===
              x?.data?.basicAccountInformation?.accountNumber &&
              y?.accountNumber !== "") ||
            x?.data?.basicAccountInformation?.productInformation.productId ===
              y?.deliveryId
        ) || []

      const { deliveryId, safetyRisk } = relatedSecurities?.[0] || {
        safetyRisk: defaultValues.safetyRisk,
        accountNumber: x?.data?.basicAccountInformation?.accountNumber,
        deliveryId:
          x.data?.basicAccountInformation?.productInformation?.productId ??
          defaultValues.deliveryId,
      }
      const safetyRiskByAccountNumber = getSafetyRisk(
        accountNumber,
        coreviewEngagements
      )

      const updatedSafetyRisk = securityRiskData?.find(
        (x) => x?.deliveryId === deliveryId
      )?.safetyRisk

      const loanSafetyRisk =
        updatedSafetyRisk && x?.data?.basicAccountInformation?.version === "NEW"
          ? updatedSafetyRisk
          : safetyRisk !== NO_SAFETY_RISK
          ? safetyRisk
          : safetyRiskByAccountNumber

      const coreViewEngagement = coreviewEngagements.find(
        (y) => y.accountName === accountNumber
      )
      const insightLoanAmount =
        x.data.specificAccountTypeInfo.initialLoanInformation.loanAmount || 0
      return {
        productName: x?.data?.basicAccountInformation?.name,
        productAmount: getLoanAmount(
          coreViewEngagement,
          insightLoanAmount,
          loanVersion
        ),
        safetyRisk: loanSafetyRisk,
        version: loanVersion,
        deliveryId,
        accountNumber,
        agreementData: agreementData,
      }
    }) || []
  return deliveries
}

/**
 * Gets the new and existing engagements for regular cases and changed loans for maintenance
 * @param {Deliveries} deliveries - The deliveries.
 * @param {CollateralEngagement[]} collateralEngagements - The collateral engagements.
 * @returns {CollateralEngagement[]} The new and existing engagements.
 */
export const getNewAndExistingEngagements = (
  deliveries,
  collateralEngagements
) => {
  // Check if changedAgreements - then we know it is maintenance
  if (deliveries?.vedlikehold?.changes) {
    /**
     * @type {Change[]} changedAgreements - The changed agreements.
     */
    const changedAgreementsAndLoans = deliveries.vedlikehold.changes
    let changedLoans = []
    if (changedAgreementsAndLoans) {
      const changeData = changedAgreementsAndLoans
        .map((change) => {
          const agreements = change.changeData
            .map((changeData) => {
              return changeData.agreementData
            })
            .flat()
          return agreements
        })
        .flat()
      changedLoans = changeData.filter((x) => x.type === "LOAN")
    }

    const filteredCollateralEngagements = collateralEngagements?.filter(
      (x) => !changedLoans.some((y) => y.internalId === x.data.internalId)
    )
    const changedCollateralEngagements = changedLoans
    const newAndExistingCollateralEngagements = [
      ...(filteredCollateralEngagements || []),
      ...(changedCollateralEngagements || []),
    ]
    return newAndExistingCollateralEngagements
  }

  const newCollateralEngagements =
    Object.values(deliveries)
      .map((delivery) => {
        return delivery.agreement
      })
      .filter(
        (x) =>
          x?.data?.internalId !== categoriesConstants.MAINTENANCE &&
          x?.data?.type === "LOAN"
      ) || []
  const newAndExistingCollateralEngagements = [
    ...(collateralEngagements || []),
    ...(newCollateralEngagements || []),
  ]
  return newAndExistingCollateralEngagements
}

/**
 * Gets the version of an agreement.
 * @param {Insight.CollateralAgreement} agreement - The insight agreement object.
 * @param {ChangesData[]} changedAgreements - The list of changed agreements.
 * @returns {string} The version of the agreement. "NEW", "ACTIVE" or "CHANGED"
 */
const getAgreementVersion = (agreement, changedAgreements) => {
  const changedAgreement = changedAgreements?.find(
    (x) => x?.data?.basicChangeInformation?.parentId === agreement?.internalId
  )
  if (changedAgreement) {
    return "CHANGED"
  }
  return agreement?.basicCollateralAgreementInformation
    ?.basicCollateralInformation.version
}

/**
 * An object mapping product codes to their corresponding product names.
 * @type {Object.<number, string>}
 * @property {string} 26 - "fast eiendom m/dr.tilbehør"
 * @property {string} 27 - "fast eiendom"
 * @property {string} 28 - "festerett m/dr.tilbehør"
 * @property {string} 29 - "festerett"
 * @property {string} 30 - "leierett m/dr.tilbehør"
 * @property {string} 31 - "motorvogner / anleggsmaskiner"
 * @property {string} 32 - "landbruk"
 * @property {string} 33 - "factoring"
 * @property {string} 34 - "varelager"
 * @property {string} 35 - "koordineringsavtale"
 * @property {string} 36 - "aksjer"
 * @property {string} 37 - "skip / fiskefartøyer"
 * @property {string} 38 - "skip under bygging"
 * @property {string} 39 - "fiskeredskaper"
 * @property {string} 40 - "flytekran / flytedokk"
 * @property {string} 42 - "luftfartøy"
 * @property {string} 43 - "kausjon / garanti"
 * @property {string} 44 - "andre objekter"
 * @property {string} 46 - "driftstilbehør"
 * @property {string} 47 - "leierett"
 * @property {string} 49 - "akvakulturtillatelse"
 * @property {string} 50 - "kausjon / næringsforhold"
 * @property {string} 51 - "kausjon / næringsforhold"
 * @property {string} 52 - "enkle pengekrav"
 */
const mappedProductCodes = {
  26: "fast eiendom m/dr.tilbehør",
  27: "fast eiendom",
  28: "festerett m/dr.tilbehør",
  29: "festerett",
  30: "leierett m/dr.tilbehør",
  31: "motorvogner / anleggsmaskiner",
  32: "landbrukspant",
  33: "factoring",
  34: "varelager",
  35: "koordineringsavtale",
  36: "aksjer",
  37: "skip / fiskefartøyer",
  38: "skip under bygging",
  39: "fiskeredskaper",
  40: "flytekran / flytedokk",
  42: "luftfartøyer",
  43: "kausjon / garanti",
  44: "annet",
  46: "driftstilbehør",
  47: "leierett",
  49: "akvakulturtillatelse",
  50: "kausjon / næringsforhold",
  51: "kausjon / forbrukerforhold",
  52: "enkle pengekrav",
}

/**
 * Gets the detail type name for a given product code.
 *
 * @param {object} agreement - The agreement object which contains product information.
 * @param {object} agreement.basicCollateralAgreementInformation - The basic collateral agreement information object.
 * @param {object} agreement.basicCollateralAgreementInformation.basicCollateralInformation - The basic collateral information object.
 * @param {object} agreement.basicCollateralAgreementInformation.basicCollateralInformation.productInformation - The product information object.
 * @param {string} agreement.basicCollateralAgreementInformation.basicCollateralInformation.productInformation.productId - The product ID.
 * @param {string} agreement.basicCollateralAgreementInformation.basicCollateralInformation.productInformation.productName - The product name.
 * @returns {string} The detail type name, which is either a mapped product code, the product name, or an empty string if neither is available.
 */
const getDetailTypeNameByProductCode = (agreement) => {
  const productInfo =
    agreement?.basicCollateralAgreementInformation?.basicCollateralInformation
      ?.productInformation
  const productId = parseInt(productInfo?.productId)

  const detailTypeName = mappedProductCodes[productId]
  if (!detailTypeName) {
    const { productName } = productInfo || {}
    return productName?.replace(/^Pant i /i, "") || ""
  }
  return detailTypeName
}

/**
 * Maps collateral agreements and collateral objects to a new structure.
 * @param {CollateralAgreement[]} agreements - The list of agreements.
 * @param {Deliveries} deliveries - The deliveries.
 * @param {boolean} isMaintenance - Indicates whether the collateral is for maintenance.
 * @returns {object} The mapped collateral agreements and collateral objects.
 */
export const mapCollateral = (
  agreements,
  deliveries,
  isMaintenance = false
) => {
  const collateralObjects = agreements
    .filter((object) =>
      Object.values(collateralObjectTypes).includes(object.type)
    )
    .map((collateralObject) => ({ ...collateralObject.data }))

  const collateralAgreements = agreements
    .filter((agreement) =>
      Object.values(collateralAgreementTypes).includes(agreement.type)
    )
    .map((agreement) => {
      const { data, ...meta } = agreement
      const uniqueCoverages =
        data.basicCollateralAgreementInformation.collateralCoverages.reduce(
          (acc, curr) => {
            const existingItem = acc.find(
              (item) => item.internalId === curr.internalId
            )
            if (!existingItem) {
              acc.push(curr)
            }
            return acc
          },
          []
        )

      data.basicCollateralAgreementInformation.collateralCoverages =
        uniqueCoverages

      return {
        meta,
        ...data,
        collateralObjectsReferences: (
          agreement?.data?.specificCollateralAgreementInformation
            ?.collateralObjects || []
        ).map((collateralObject) => collateralObject.internalId),
      }
    })

  const deliveryAgreement = deliveryByAgreementId(deliveries)
  const getDeliveryIdFromAgreementId = (agreementId) =>
    deliveryAgreement[agreementId]

  // Find collateral per delivery
  const collateralAgreementsForDelivery = collateralAgreements.reduce(
    (deliveryAgreements, _agreement) => {
      const agreement = _.cloneDeep(_agreement)
      const collateralCoverages =
        agreement?.basicCollateralAgreementInformation?.collateralCoverages ||
        []

      let agreementIds = []
      if (isMaintenance) {
        agreementIds = ["maintenance"]
      } else {
        agreementIds =
          collateralCoverages
            .map((collateralCoverage) => {
              const coverageInternalId = collateralCoverage.internalId
              return agreements.find((x) => x.internalId === coverageInternalId)
            })
            .filter(
              (x) =>
                Boolean(x) &&
                x?.data?.basicAccountInformation?.version === "NEW"
            )
            .map((x) => x.internalId) || []
      }

      let isMainDeliverySet = false
      agreementIds.forEach((agreementId, i) => {
        const deliveryId = getDeliveryIdFromAgreementId(agreementId)

        if (!deliveryAgreements[deliveryId]) {
          deliveryAgreements[deliveryId] = {
            collateralAgreements: [],
            collateralObjects: [],
          }
        }
        let isMainDelivery = false

        //Dersom det er et nytt lån, legg dette som en leveranse
        if (
          !isMainDeliverySet &&
          agreement?.basicCollateralAgreementInformation
            ?.basicCollateralInformation?.version === "NEW"
        ) {
          isMainDelivery = true
          isMainDeliverySet = true
        }

        const mappedAgreement = {
          ...agreement,
          isMainDelivery,
        }

        deliveryAgreements[deliveryId].collateralAgreements.push(
          mappedAgreement
        )

        let collateralObjectsIds = deliveryAgreements[
          deliveryId
        ].collateralObjects.map((x) => x.internalId)
        deliveryAgreements[deliveryId].collateralObjects.push(
          ...collateralObjects
            .filter((collateralObject) =>
              mappedAgreement.collateralObjectsReferences.some(
                (id) => id === collateralObject.internalId
              )
            )
            .filter(
              (collateralObject) =>
                !collateralObjectsIds.includes(collateralObject)
            )
            .map(_.cloneDeep)
        )
      })
      return deliveryAgreements
    },
    {}
  )

  return {
    collateralAgreements,
    collateralObjects,
    collateralAgreementsForDelivery,
  }
}

/**
 * @typedef {object} AgreementsDataState
 * @property {Insight.CollateralAgreementDto[] | Insight.CollateralEngagement[]} agreementData - The list of agreements and engagements.
 * @property {ChangesData[]} changesData - The list of changes.
 * @property {object} collateral - The collateral data.
 * @property {Insight.CollateralAgreement[]} collateral.collateralAgreements - The list of collateral agreements.
 * @property {Insight.CollateralObject[]} collateral.collateralObjects - The list of collateral objects.

 * @typedef {object} MappedCollateralAgreement
 * @property {string} agreementId - The ID of the agreement.
 * @property {string} agreementType - The type of the agreement.
 * @property {number} collateralAmount - The amount of the collateral.
 * @property {MappedCollateralObject[]} collateralObject - The list of collateral objects.
 * @property {MappedCollateralObjectSecurity[]} collateralObjectSecurity - The list of collateral object securities.
 * @property {string} expiryDate - The expiry date of the agreement.
 * @property {number} maximumAmount - The maximum amount of the agreement.
 * @property {string[]} mortgagors - The list of mortgagors.
 * @property {string} version - The version of the agreement. "NEW", "ACTIVE" or "CHANGED"
 * @property {string} productCode - The product code of the agreement.
 */

/**
 * @typedef {object} MappedCollateralObject
 * @property {number} collateralObjectsValue - The value of the collateral object.
 * @property {string[]} collateralObjectCopyRightHolder - The list of copy right holders.
 * @property {object} carStuff - The car stuff.
 * @property {string} description - The description of the collateral object.
 * @property {string} marketValueDate - The market value date of the collateral object.
 * @property {string} collateralObjectCadastre - The cadastre of the collateral object.
 * @property {string[]} collateralAssosciatedProperties - The list of collateral associated properties.
 */

/**
 * @typedef {object} MappedCollateralObjectSecurity
 * @property {string} productName - The name of the product.
 * @property {number} productAmount - The amount of the product.
 * @property {string} safetyRisk - The safety risk of the product.
 * @property {string} version - The version of the product.
 * @property {string} deliveryId - The ID of the delivery.
 * @property {string} accountNumber - The account number of the product.
 */

/**
 * @typedef {object} CollateralObject - The collateral object.
 * @property {string} internalId - The internal ID of the collateral object.
 * @property {string} [externalId] - The external ID of the collateral object.
 * @property {string} [externalSource] - The external source of the collateral object.
 * @property {string} caseId - The case ID of the collateral object.
 * @property {number} [dataversion] - The data version of the collateral object.
 * @property {object} basicCollateralObjectInformation - Basic information about the collateral object.
 * @property {CollateralObjectDetails} collateralObjectDetails - Details about the collateral object.
 */

/**
 * @typedef {object} CollateralObjectDetails - The collateral object details.
 * @property {string} internalId - The internal ID of the collateral object details.
 * @property {Object[]} liens - An array of liens.
 * @property {Object[]} propertyTypeUsage - An array of property type usage.
 * @property {object} residentialPropertyUsage - The residential property usage.
 * @property {boolean} sharedWithSpouse - Indicates whether the collateral object is shared with a spouse.
 * @property {"EXCLUSIVE_OWNERSHIP"|string} ownershipType - The ownership type of the collateral object.
 * @property {boolean} hasAllodialRights - Indicates whether the collateral object has allodial rights.
 * @property {object} addressInformation - The address information of the collateral object.
 * @property {object} municipality - The municipality of the collateral object.
 * @property {string} municipality.municipalityName - The municipality name of the collateral object.
 * @property {import("../../../../../setup/process/_common/handlers/lookup-stakeholder-ambita").Cadastre} cadastre - The cadastre of the collateral object.
 * @property {Object[]} [associatedProperties] - An array of associated properties of the collateral object.
 * @property {object} faceValue - The face value of the collateral object.
 * @property {RegistrationData} [registrationData] - The registration data of the collateral object. Vehicle specific.
 */

/**
 * @typedef {object} RegistrationData - The registration data.
 * @property {string} registrationNumber - The registration number of the registration data.
 * @property {string} vehicleIdentificationNumber - The vehicle identification number of the registration data.
 * @property {string} vehicleBrand - The vehicle brand of the registration data.
 * @property {string} vehicleModel - The vehicle model of the registration data.
 * @property {string} vehicleType - The vehicle type of the registration data.
 * @property {string} vehicleSubType - The vehicle sub type of the registration data.
 */

/**
 * This property is set under basicCollateralObjectInformation
 * @typedef {object} CollateralValuation - The collateral valuation.
 * @property {object} marketValuation - The market valuation of the collateral valuation.
 * @property {number} marketValuation.amount - The amount of the market valuation.
 * @property {object} marketValuation.currency - The currency of the market valuation.
 * @property {string} marketValuation.marketValueDate - The market value date of the market valuation.
 * @property {boolean} marketValuation.selectedAsMarketValue - Indicates whether the market valuation is selected as market value.
 * @property {boolean} deviatingValuation - Indicates whether the valuation is deviating.
 * @property {string} comments - The comments of the valuation.
 * @property {number} reductionFactorPercent - The reduction factor percent of the valuation.
 */

/**
 * @typedef {object} BasicCollateralAgreementInformation
 * @property {object} basicCollateralInformation - Basic information about the collateral agreement.
 * @property {string} basicCollateralInformation.agreementNumber - The agreement number.
 * @property {string} basicCollateralInformation.name - The name of the agreement.
 * @property {string} basicCollateralInformation.description - The description of the agreement.
 * @property {string} basicCollateralInformation.subDescription - The sub-description of the agreement.
 * @property {string} basicCollateralInformation.version - The version of the agreement.
 * @property {object} basicCollateralInformation.productInformation - Information about the product related to the agreement.
 * @property {string} basicCollateralInformation.productInformation.productCode - The product code.
 * @property {string} basicCollateralInformation.productInformation.productName - The product name.
 * @property {string} basicCollateralInformation.productInformation.marketingName - The marketing name of the product.
 * @property {string} basicCollateralInformation.productInformation.productId - The product ID.
 * @property {Object[]} collateralCoverages - An array of collateral coverages.
 * @property {Object[]} mortgagees - An array of mortgagees.
 * @property {string} mortgagees[].internalId - The internal ID of a mortgagee.
 * @property {Object[]} mortgagors - An array of mortgagors.
 * @property {string} mortgagors[].internalId - The internal ID of a mortgagor.
 */

/**
 * @typedef {object} CollateralCoverage
 * @property {string} internalId - The internal ID of the collateral coverage.
 * @property {string} accountNumber - The account number of the collateral coverage.
 * @property {string} accountName - The account name of the collateral coverage.
 * @property {number} amount - The amount of the collateral coverage.
 * @property {object} collateralCoverage - The collateral coverage.
 * @property {string} collateralCoverage.coverageType - The coverage type of the collateral coverage.
 */

/**
 * @typedef {object} SpecificCollateralAgreementInformation
 * @property {string} mortgageDeedType - The type of the mortgage deed.
 * @property {boolean} suretyship - Indicates whether the agreement includes a suretyship.
 * @property {object} mortgageDeedDetails - Details about the mortgage deed.
 * @property {number} mortgageDeedDetails.faceValue - The face value of the mortgage deed.
 * @property {string} mortgageDeedDetails.registrationNumber - The registration number of the mortgage deed.
 * @property {Object[]} collateralObjects - An array of collateral objects related to the agreement.
 * @property {Object[]} collaterals - An array of collaterals related to the agreement.
 * @property {Object[]} [realEstateProperties] - An array of real estate properties related to the agreement.
 */

/**
 * @typedef {object} CollateralAgreementData
 * @property {string} internalId - The internal ID of the agreement.
 * @property {string} externalId - The external ID of the agreement.
 * @property {string} externalSource - The external source of the agreement.
 * @property {string} caseId - The case ID of the agreement.
 * @property {number} [dataversion] - The data version of the agreement.
 * @property {string} collateralAgreementType - The type of the collateral agreement.
 * @property {BasicCollateralAgreementInformation} basicCollateralAgreementInformation - Basic information about the collateral agreement.
 * @property {SpecificCollateralAgreementInformation} specificCollateralAgreementInformation - Specific information about the collateral agreement.
 */

/**
 * @typedef {object} CollateralAgreement
 * @property {string} internalId - The internal ID of the agreement.
 * @property {string} type - The type of the agreement.
 * @property {string} domain - The domain of the agreement.
 * @property {string} [created] - The creation date of the agreement.
 * @property {string} [createdBy] - The creator of the agreement.
 * @property {string} [updated] - The update date of the agreement.
 * @property {string} [updatedBy] - The updater of the agreement.
 * @property {string} [schemaUrl] - The schema URL of the agreement.
 * @property {CollateralAgreementData} data - The data of the agreement.
 */

/**
 * @typedef {object} Change - The change.
 * @property {string} id - The id.
 * @property {string} type - The type.
 * @property {ChangesData[]} changeData - The change data.
 */

/**
 * @typedef {object} ChangesData
 * @property {string} internalId - The ID of the change.
 * @property {string} type - The type of the change.
 * @property {object} data - The data of the change.
 * @property {object} data.reducedNominalAmount - The reduced nominal amount data.
 * @property {number} data.reducedNominalAmount.amount - The amount of the reduced nominal amount.
 * @property {object} data.basicChangeInformation - The basic change information data.
 * @property {string} data.basicChangeInformation.caseId - The ID of the case.
 * @property {string} data.basicChangeInformation.parentId - The ID of the parent.
 * @property {Array} data.paymentPeriods - The list of payment periods.
 * @property {object} data.agreementData - The agreement data.
 */

/**
 * @typedef {Object.<string, DeliveryItem>} Deliveries
 * The main type representing the deliveries, where each key is a UUID and the value is a `DeliveryItem`.
 */

/**
 * @typedef {object} DeliveryItem
 * @property {string} productName - The name of the product.
 * @property {string} productFullName - The full name of the product.
 * @property {string} productType - The type of the product.
 * @property {string} loanPurposeCode - The loan purpose code.
 * @property {string} productClass - The class of the product.
 * @property {number} amount - The amount of the product.
 * @property {boolean} canSkipFinancialAnalysis - Indicates whether the financial analysis can be skipped.
 * @property {boolean} skipFinalReport - Indicates whether the final report can be skipped.
 * @property {string} payoutDueDate - The payout due date.
 * @property {object} totalSubsidyEffect - The total subsidy effect.
 * @property {boolean} totalSubsidyEffect.showSubsidyEffect - Indicates whether the subsidy effect should be shown.
 * @property {number} totalSubsidyEffect.subsidyEffect - The subsidy effect.
 * @property {string[]} subsidyRegime - The list of subsidy regimes.
 * @property {object} subproducts - The subproducts.
 * @property {Object[]} costBoundary - The list of cost boundaries.
 * @property {object} agreement - The agreement.
 * @property {Object[]} specialTerms - The list of special terms.
 * @property {Object[]} securityTexts - The list of security texts.
 * @property {Object[]} coDebtors - The list of co-debtors.
 * @property {string} id - The ID of the delivery.
 * @property {Change[]} [changes] - The changes. (for maintenance cases only)
 */

/**
 * @typedef {object} DeliveryProduct
 * @property {string} productName - The name of the product.
 * @property {number} productAmount - The amount of the product, derived from the initial loan information. Defaults to 0 if not available.
 * @property {number} safetyRisk - The safety risk associated with the product. Currently hardcoded to 0.
 * @property {"NEW"|"ACTIVE"} version - The version of the product. Can be either "NEW" or "ACTIVE".
 * @property {string} deliveryId - The ID of the delivery.
 * @property {string} accountNumber - The account number associated with the product. Currently hardcoded to an empty string.
 */

/**
 * @typedef {object} DeliveryProductWithAgreementData
 * @property {string} productName - The name of the product.
 * @property {number} productAmount - The amount of the product, derived from the initial loan information. Defaults to 0 if not available.
 * @property {number} safetyRisk - The safety risk associated with the product. Currently hardcoded to 0.
 * @property {"NEW"|"ACTIVE"|"CHANGED"} version - The version of the product. Can be either "NEW" or "ACTIVE".
 * @property {string} deliveryId - The ID of the delivery.
 * @property {string} accountNumber - The account number associated with the product. Currently hardcoded to an empty string.
 * @property {MappedCollateralAgreement[]} agreementData - The data of the agreement associated with the product.
 */

/**
 * @typedef {object} CollateralEngagement
 * @property {CollateralEngagementData} data - The internal ID of the engagement.
 *
 * @typedef {object} CollateralEngagementData - The data of the engagement.
 * @property {BasicAccountInformation} basicAccountInformation - The basic account information of the engagement.
 * @property {SpecificAccountTypeInfo} specificAccountTypeInfo - The specific account type information of the engagement.
 *
 * @typedef {object} BasicAccountInformation - The basic account information of an engagement.
 * @property {string} accountNumber - The account number of the engagement.
 * @property {string} name - The name of the engagement.
 * @property {string} version - The version of the engagement.
 * @property {string} accountType - The type of the engagement.
 * @property {object[]} accountRoles - The roles of the engagement.
 * @property {string} accountRoles.id - The ID of the role.
 * @property {string} accountRoles.role - The role of the engagement.
 * @property {string} accountRoles.share - The share of the engagement.
 * @property {object} accountBalance - The balance of the engagement.
 * @property {number} accountBalance.bookedBalance - The booked balance of the engagement.
 * @property {number} accountBalance.bookedBalanceInLocalCurrency - The booked balance in local currency of the engagement.
 * @property {number} accountBalance.arrearsAmount - The arrears amount of the engagement.
 * @property {string} accountBalance.balanceDate - The balance date of the engagement.
 * @property {object} productInformation - The product information of the engagement.
 * @property {string} productInformation.productCode - The product code of the engagement.
 * @property {string} productInformation.productName - The product name of the engagement.
 * @property {string} productInformation.productId - The product ID of the engagement.
 *
 * @typedef {object} SpecificAccountTypeInfo - The specific account type information of an engagement.
 * @property {object} initialLoanInformation - The initial loan information of the engagement.
 * @property {number} initialLoanInformation.loanAmount - The loan amount of the engagement.
 * @property {"SERIAL" | "ANNUITY"} loanType - The loan type of the engagement.
 * @property {object} maturity - The maturity of the engagement.
 * @property {number} maturity.numberOfMonths - The number of months of the maturity of the engagement.
 * @property {object} gracePeriod - The grace period of the engagement.
 * @property {number} gracePeriod.numberOfMonths - The number of months of the grace period of the engagement.
 * @property {object} interestFreePeriod - The interest free period of the engagement.
 * @property {number} interestFreePeriod.numberOfMonths - The number of months of the interest free period of the engagement.
 * @property {object} paymentInformation - The payment information of the engagement.
 * @property {PaymentFrequency} paymentInformation.paymentFrequency - The payment frequency of the engagement.
 * @property {string} paymentInformation.nextDueDate - The next due date of the engagement.
 * @property {object} interestProfile - The interest profile of the engagement.
 * @property {string} interestProfile.interestProfileId - The interest profile ID of the engagement.
 * @property {string} interestProfile.interestRateType - The interest rate type of the engagement.
 * @property {boolean} interestProfile.accumulateInterest - The accumulate interest of the engagement.
 * @property {string} interestProfile.interestRateAdjustmentType - The interest rate adjustment type of the engagement.
 * @property {object} interestProfile.interestLine - The interest line of the engagement.
 * @property {object} interestProfile.interestLine.interestRates - The interest rates of the engagement.
 * @property {object} interestProfile.interestLine.interestRates.baseRate - The base rate of the engagement.
 * @property {number} interestProfile.interestLine.interestRates.baseRate.value - The value of the base rate of the engagement.
 * @property {object} interestProfile.interestLine.interestRates.marginRate - The margin rate of the engagement.
 * @property {number} interestProfile.interestLine.interestRates.marginRate.marginRate.value - The value of the margin rate of the engagement.
 * @property {object[]} feeRates - The fee rates of the engagement.
 *
 * @typedef {"YEARLY" | "BIANNUALLY" | "QUARTERLY" | "TRIANNUALLY" | "BIMONTHLY" | "MONTHLY"} PaymentFrequency - The payment frequency.
 *
 * @typedef {object} CoreviewEngagement
 * @property {string} accountName
 * @property {"Loan"|"Grant"|"Guarantee"|"BackingGuarantee"} category
 * @property {"Active"|"Approved"} publicStatus
 * @property {"Innvilget"|"Aktiv"} publicStatusDisplayName
 * @property {string} coreViewStateCode - eg "11"
 * @property {CoreViewStateNames} coreViewStateName
 * @property {string} typeCode
 * @property {string} typeName
 * @property {string} projectNumber
 * @property {string} purposeCode
 * @property {string} purposeName - eg "120 GFL-FLÅTE"
 * @property {number} bookBalance
 * @property {number} availableAmount
 * @property {number} totalAmount
 * @property {number} limitAmount
 * @property {number} blockedAmount
 * @property {number} anulledAmount
 * @property {number} payoutAmount
 * @property {string} approveDate
 * @property {string} expiryDate
 * @property {string} interestType
 * @property {string} fixedInterestExpiryDate
 * @property {string} paymentMethod
 * @property {number} interestRate
 * @property {string} effectiveInterestRate
 * @property {number} openFee
 * @property {string} riskGroupCode
 * @property {string} paymentInterval
 * @property {string} arrears
 * @property {number} [arrearsAmount]
 * @property {string} nextPrincipalDate
 * @property {string} amortizationFreePeriods
 * @property {string} orgNo
 * @property {string} companyName
 * @property {object} [periods] - loan specific
 * @property {number} periods.total
 * @property {number} periods.remaining
 * @property {object} [paymentFreePeriods]
 * @property {number} paymentFreePeriods.total
 * @property {number} paymentFreePeriods.remaining
 * @property {object} [interestFreePeriods]
 * @property {number} interestFreePeriods.total
 * @property {number} interestFreePeriods.remaining
 * @property {object} [amortizationFreePeriods]
 * @property {number} amortizationFreePeriods.total
 * @property {number} amortizationFreePeriods.remaining
 *
 * @typedef {Object} CoreviewLossAssessmentEngagement
 * @property {string} accountName
 * @property {"Loan"|"Guarantee"|"Grant"|"BackingGuarantee"|"Unknown"|"InterestSubsidy"} accountType
 * @property {string} approveDate
 * @property {string} typeCode
 * @property {string} typeName
 * @property {number} bookBalance
 * @property {number} anulledAmount
 * @property {number} availableAmount
 * @property {number} payoutAmount
 * @property {string} publicStatusDisplayName
 * @property {string} purposeName
 * @property {string} stateCode
 * @property {number} blockedAmount
 * @property {string} stateName
 * @property {string} riskGroupCode
 * @property {number} originalAmount
 * @property {number} periodInterestAmount
 * @property {number} unpaidInterestAmount
 * @property {number} expensesAmount
 * @property {number} daysOverdue
 * @property {number} effectiveRate
 * @property {string} nextDueDate
 * @property {string} companyName - Company name
 * @property {string} orgNo - Organization number
 */

/**
 * @typedef {"Løpende" | "Innvilget" | "Avtale" | "Endret" | "Delutbetalt" | "Forespørsel sluttfak" | "Sluttfaktura" | "Delinnbetalt" | "Sammenslåing" | "Konkurs" | "Tapsført"} CoreViewStateNames - The different states a case can be in
 *
 * @typedef {"ACTIVE" | "CHANGED" | "NEW"} InsightVersion - The different states a case can be in
 */
