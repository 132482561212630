import React, { useEffect } from "react"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import { useFormContext, Controller } from "react-hook-form"
import { Input } from "@staccx/bento"
import { calculatePercentageOf } from "../../../util/calculatePercentageOf"

const GuaranteeFieldsLine = ({ t, index, currentProduct }) => {
  const { control, setValue, getValues } = useFormContext()
  const { amount, creditAmount } = getValues().products[index]
  const { guaranteePercentages } = currentProduct
  const sortedGuaranteePercentages = guaranteePercentages.sort(
    (a, b) => a.creditLimit - b.creditLimit
  )

  const guaranteePercent = calculatePercentageOf(amount, creditAmount)
  const commission =
    sortedGuaranteePercentages.find(
      (item) => guaranteePercent <= item.creditLimit * 100
    )?.percentage || 0

  // useEffect used because it's the least hacky way to have a derived state here.
  useEffect(() => {
    setValue(`products.${index}.commissionRate`, commission * 100)
  }, [commission])

  return (
    <>
      <LabelAndValue>
        <InputLabel>{t("granted-credit-in-bank")}</InputLabel>
        <StyledInput>
          <Controller
            name={`products.${index}.creditAmount`}
            control={control}
            key={`products.${index}.creditAmount`}
            render={({ field: { name, onChange, onBlur, ...rest } }) => (
              <Input
                name={name}
                id={name}
                mode="currency"
                inputMode="numeric"
                pattern={/[0-9]*/}
                type="text"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  setValue(`products.${index}.creditAmount`, e.rawValue)
                }
                onBlur={(e) => onBlur(e.rawValue)}
                {...rest}
              />
            )}
          />
        </StyledInput>
      </LabelAndValue>
      <LabelAndValue>
        <InputLabel>{t("guarantee-percentage")}</InputLabel>
        <p>{guaranteePercent.toFixed(2)} %</p>
      </LabelAndValue>
      <LabelAndValue>
        <InputLabel>{t("guarantee-commission")}</InputLabel>
        <p>{(commission * 100).toFixed(2)} %</p>
      </LabelAndValue>
      <LabelAndValue>
        <InputLabel>{t("guarantee-maturity")}</InputLabel>
        <StyledInput style={{ width: "60px" }}>
          <Controller
            name={`products.${index}.maturity`}
            control={control}
            key={`products.${index}.maturity`}
            render={({ field: { name, onChange, onBlur, ...rest } }) => (
              <Input
                name={name}
                id={name}
                mode="currency"
                inputMode="numeric"
                pattern={/[0-9]*/}
                type="text"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  setValue(`products.${index}.maturity`, e.rawValue)
                }
                onBlur={(e) => onBlur(e.rawValue)}
                {...rest}
              />
            )}
          />
        </StyledInput>
      </LabelAndValue>
      <LabelAndValue>
        <InputLabel>{t("guarantee-name-of-bank")}</InputLabel>
        <StyledInput>
          <Controller
            name={`products.${index}.nameOfBank`}
            control={control}
            key={`products.${index}.nameOfBank`}
            render={({ field: { name, onChange, onBlur, ...rest } }) => (
              <Input
                name={name}
                id={name}
                type="text"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  setValue(`products.${index}.nameOfBank`, e.target.value)
                }
                onBlur={(e) => onBlur(e.target.value)}
                {...rest}
              />
            )}
          />
        </StyledInput>
      </LabelAndValue>
    </>
  )
}

const LabelAndValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 0;
`

const InputLabel = styled.label`
  font-size: 12px;
  line-height: 1.4em;
  letter-spacing: 0.01em;
  padding: 0.2em;
  text-transform: uppercase;
`

const StyledInput = styled.div`
  display: flex;
  input {
    align-self: flex-start;
    padding: 3px 4px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    text-align: right;
  }
`

export default GuaranteeFieldsLine
